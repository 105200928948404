import {
  Box,
  Button,
  FormControl,
  TextField,
  Container,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import { CreateCandidateValidationSchema } from "../../utils/Validations";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  patchRequestWithToken,
  postRequest,
  getRequestWithToken,
  getRequest,
  getRequestWithTokenV3,
} from "../../utils/ApiRequests";
import { useContext, useEffect, useRef, useState } from "react";
import "./candidates.scss";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import FocusError from "../../components/FocusError/FocusError";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { TimeDisplay } from "../../utils/functions";

const CreateCandidates = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([]);
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    date_of_birth: null,
    gender: "",
    address: "",
    district: "",
    state: "",
    city: "",
    pincode: "",
    marital_status: "",
    dependent_members: "",
    level_of_physical_fitness: [],
    social_category: "",
    pwd_category: "",
    applicable_category: [],
    migrated: "",
    assets_owned: [],
    government_document: [],
    educational_specialisation_data: [],
    qualification: [],
    qualificationsDetail: [],
    certification: [],
    certificationsDetail: [],
    languages: [],
    languagesDetail: [],
    languagesData: [],
    english_proficiency: "",
    employment_status: "",
    past_work_exp_m: "",
    past_work_exp_y: "",
    experiences: [],
    experienceDetails: [],
    work_preference: "",
    work_made_choice: [],
    engagement_type_preference: "",
  });
  const [editForm, setEditForm] = useState(false);

  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const experienceFormat = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const formatDateOfBirth = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    // Convert month number to month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[parseInt(month) - 1];

    return `${day} ${monthName},${year}`;
  };

  const handleSubmit = async (values, { resetForm }) => {
    let formData = new FormData();
    let qualificationPayload =
      values.qualificationsDetail.length > 0
        ? values.qualificationsDetail.map((item) => {
            return {
              qualification: item.qualification?.id,
              completion_year: item.year_of_completion,
              qualification_stream: item.specialisation,
              school: {
                name: item.school_name,
                address: item.school_address,
              },
              medium_of_education:
                item.medium_of_education === "" ? 0 : item.medium_of_education,
              score: {
                type: item.score_type === "Percentage" ? 1 : 2,
                value: item.score,
              },
            };
          })
        : [];

    let CertificationPayload =
      values.certificationsDetail.length > 0
        ? values.certificationsDetail.map((item) => {
            return {
              name: item.certification?.id,
              stream: item.specialisation,
              duration: {
                year: item.course_years,
                months: item.course_month,
              },
              completion_year: item.year_of_completion,
              medium_of_education:
                item.medium_of_education === "" ? 0 : item.medium_of_education,
              school: {
                name: item.school_name,
                address: item.school_address,
                learning_mode:
                  item.learning_mode === "" ? 0 : item.learning_mode,
              },
            };
          })
        : [];
    const languagePayload =
      values.languagesDetail.length > 0
        ? values.languagesDetail.map((item) => {
            return {
              value: item.language?.id,
              read: item.can_read === "Yes" ? true : false,
              speak: item.can_speak === "Yes" ? true : false,
              write: item.can_write === "Yes" ? true : false,
            };
          })
        : [];

    let experiencePayload =
      values.experienceDetails.length > 0
        ? values.experienceDetails.map((item) => {
            return {
              job_role: item.job_role,
              company_name: item.employer_company,
              address: item.employer_address,
              employment_period: {
                from: experienceFormat(item.employement_starting_time),
                to: experienceFormat(item.employement_ending_time),
                currently_working: item.currently_working
                  ? item.currently_working
                  : false,
              },
              engagement_type:
                item.engagement_type === ""
                  ? 0
                  : item.engagement_type === "Full Time"
                  ? 1
                  : 2,
              Salary_per_month: item.salary,
            };
          })
        : [];
    let PastWorkExp;
    if (values.past_work_exp_m && values.past_work_exp_y) {
      PastWorkExp =
        parseInt(values.past_work_exp_y * 12) +
        parseInt(values.past_work_exp_m);
      PastWorkExp = PastWorkExp.toString();
    } else {
      PastWorkExp = "";
    }
    const workModeChoice =
      values.work_made_choice.length === 0
        ? []
        : values.work_made_choice.map((item) => item.id);
    formData = {
      full_name: [values.first_name, values.middle_name, values.last_name]
        .filter(Boolean)
        .join(" "),
      email: values.email,
      dob: values.date_of_birth ? formatDateOfBirth(values.date_of_birth) : "",
      mobile: values.mobile_number,
      gender: values.gender === "" ? 0 : values.gender,
      district: values.district,
      state: values.state,
      city: values.city,
      pincode: values.pincode,
      address: values.address,
      marital_status: values.marital_status === "" ? 0 : values.marital_status,
      physical_fitness_level: values.level_of_physical_fitness.map(
        (item) => item.id
      ),
      social_category:
        values.social_category === "" ? 0 : values.social_category,
      accessibility_info: {
        is_pwd_category: values.pwd_category === "Yes" ? true : false,
        applied_category:
          values.pwd_category === ""
            ? []
            : values.applicable_category.map((item) => item.id),
      },
      dependent_family_members: values?.dependent_members || 0,
      // is_pwd_category: values.pwd_category === "Yes" ? true : false,
      // pwd_applied_category: values.applicable_category.map((item) => item.id),
      migrate_concerns: values.migrated === "Yes" ? true : false,
      assets_owned: values.assets_owned.map((item) => item.id),
      documents_possess: values.government_document.map((item) => item.id),
      education_information: qualificationPayload,
      certification: CertificationPayload,
      known_languages: languagePayload,
      english_proficiency:
        values.english_proficiency === "" ? 0 : values.english_proficiency,
      current_employment_status:
        values.employment_status === "" ? 0 : values.employment_status,
      experience_details: experiencePayload,
      past_work_experience: PastWorkExp,

      work_mode_choice: workModeChoice,
      engagement_type_preference: values.engagement_type_preference
        ? values.engagement_type_preference
        : 0,
      location_preference:
        values.work_preference === "" ? 0 : values.work_preference,
      usertype: "job_seeker",
    };

    try {
      let res = editForm
        ? await patchRequestWithToken("admin", {
            userid: location.state._id,
            ...formData,
          })
        : await postRequest("admin", formData);
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm();

          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate("/candidate");
          }, 1000);
        } else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate("/candidate");
          }, 1000);
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const fetchPincodeDetails = async (pincode, setFieldValue) => {
    if (!pincode) {
      setFieldValue("district", "");
      setFieldValue("state", "");
      setFieldValue("city", "");
      return;
    }
    try {
      let response = await getRequest(`pincode/${pincode}`);
      const data = response?.data?.data;
      setFieldValue("district", data?.Districtname);
      setFieldValue("state", data?.statename);
      setFieldValue("city", data?.Taluk);
    } catch (err) {
      console.log(err);
    }
  };

  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(
        `admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`
      );
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setShowLoader(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"));
  };

  const physicalFitnessData = [
    {
      id: 1,
      value: 1,
      label: "Open to Physical Activity",
    },
    {
      id: 2,
      value: 2,
      label: "6/6 Vision with Spectacles",
    },
    {
      id: 3,
      value: 3,
      label: "No Hearing Impairment",
    },
  ];

  const pwdCategoryData = [
    { id: 2, value: "acid attack victim", label: "Acid Attack Victim" },
    { id: 3, value: "dwarfism", label: "Dwarfism" },
    { id: 4, value: "locomotor disability", label: "Locomotor Disability" },
    { id: 5, value: "visual impairment", label: "Visual Impairment" },
    {
      id: 6,
      value: "intellectual disability",
      label: "Intellectual Disability",
    },
    { id: 7, value: "mental disorder", label: "Mental Disorder" },
    { id: 8, value: "neurological disorder", label: "Neurological Disorder" },
  ];

  const assets_ownedRequired = [
    { id: 1, label: "Two Wheeler", value: 1 },
    { id: 2, label: "Four Wheeler", value: 2 },
    { id: 3, label: "Basic Phone", value: 3 },
    { id: 4, label: "Smartphone", value: 4 },
    { id: 5, label: "Own Laptop", value: 5 },
    { id: 6, label: "Internet Connection", value: 6 },
    { id: 7, label: "Tools and Requirement", value: 7 },
  ];

  const governmentDocumentsRequired = [
    { id: 1, label: "Aadhaar Card", value: 1 },
    { id: 2, label: "PAN Card", value: 2 },
    { id: 3, label: "E-Shram Card", value: 3 },
    {
      id: 4,
      label: "Bank Account with passbook",
      value: 4,
    },
    { id: 5, label: "Education Certificate", value: 5 },
    { id: 6, label: "Driving License", value: 6 },
  ];

  const educationQualificationData = [
    { id: 2, label: "10th", value: "10th pass" },
    { id: 3, label: "12th", value: "12th pass" },
    { id: 4, label: "Diploma after 10th", value: "Diploma after 10th" },
    {
      id: 5,
      label: "Diploma after 12th",
      value: "Diploma after 12th",
    },
    { id: 6, label: "Graduate", value: "Graduate" },
    { id: 7, label: "Post Graduate", value: "Post Graduate" },
  ];

  const certificateSpeciliazationData = [
    { id: 0, label: "None", value: 0 },
    { id: 1, label: "ITI", value: 1 },
    { id: 2, label: "DDUGKY", value: 2 },
    { id: 3, label: "Skill India", value: 3 },
  ];

  const commericalLicense = [
    {
      id: 1,
      label: "Commercial Driving License -LMV",
      value: "commercial driving license -lmv",
    },
    {
      id: 2,
      label: "Commercial Driving License -MGV",
      value: "commercial driving license -mgv",
    },
    {
      id: 3,
      label: "Commercial Driving License -HMV",
      value: "commercial driving license -hmv",
    },
    {
      id: 4,
      label: "Commercial Driving License -HGMV",
      value: "commercial driving license -hgmv",
    },
    {
      id: 5,
      label: "Commercial Driving License -HTV",
      value: "commercial driving license -htv",
    },
    {
      id: 6,
      label: "Commercial Driving License -Trailer",
      value: "commercial driving license -trailer",
    },
  ];

  const englishProficiency = [
    { id: 1, label: "No English", value: "no english" },
    { id: 2, label: "Thoda English", value: "thoda english" },
    { id: 3, label: "Good English", value: "good english" },
    { id: 4, label: "Proficient", value: "proficient" },
  ];
  const employementStatus = [
    { id: 1, label: "Fresher", value: "fresher" },
    {
      id: 2,
      label: "unemployed & Experienced",
      value: "unemployed & experienced",
    },
    { id: 3, label: "Working Full Time", value: "working full time" },
    { id: 4, label: "Working Part Time", value: "working part time" },
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 25 }, (_, index) => currentYear - index);

  const getSpecilizationData = async (
    documents,
    setFieldValue,
    document_type
  ) => {
    try {
      let specializationData = await getRequestWithToken(
        `/initiatives/specialisation?specialisation=${documents}`
      );
      specializationData = specializationData?.data;
      if (specializationData.status === "Success") {
        if (!setFieldValue) return specializationData?.data;
      } else throw new Error(`Something went wrong`);
    } catch (err) {
      console.log(err);
    }
  };

  const getLanguageData = async () => {
    try {
      let languageData = await getRequestWithTokenV3(`candidate/languages`);
      if (languageData.status === "Success") {
        languageData = languageData?.data.filter((item) => item.label !== "NA");

        languageData = languageData?.map((item) => ({
          label: item.label,
          value: item.label,
          id: item.id,
        }));
        setInitialValues((data) => ({
          ...data,
          languagesData: languageData,
        }));
      } else {
        setInitialValues((data) => ({ ...data, languagesData: [] }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Example usage
  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
    getLanguageData();

    if (location.state?._id) {
      getCandidateById();
    } else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        first_name = "",
        middle_name = "",
        last_name = "",
        dob = "",
        gender = "",
        pincode = "",
        district = "",
        state = "",
        address = "",
        social_category = "",
        migrate_concerns = "",
        police_verification_concerns = "",
        assets_owned = [],
        documents_possess = [],
        qualification_stream = " ",
        certificate_stream = " ",
        certificate_completion_year = " ",
        commercial_license = [],
        english_proficiency = "",
        current_employment_status = "",
        past_work_experience = "",
        physical_fitness_level = [],
        drug_test_concerns = "",
        qualification = " ",
        graduationYear = " ",
        education_score = " ",
        mobile = "",
        relevent_skill_or_vocation_certification = "",
        email = "",
        accessibility_info = [],
      } = candidate;

      const [exp_y, exp_m] = past_work_experience.split(".");
      let assetsOwned = assets_owned?.map((item) => item.toLowerCase());
      assetsOwned = assets_ownedRequired?.filter((item) =>
        assetsOwned.includes(item.label.toLowerCase())
      );

      let documentsPossess = documents_possess?.map((item) =>
        item.toLowerCase()
      );
      documentsPossess = governmentDocumentsRequired?.filter((item) =>
        documentsPossess.includes(item.label.toLowerCase())
      );

      let physicalFitness = physical_fitness_level?.map((item) =>
        item.toLowerCase()
      );
      physicalFitness = physicalFitnessData?.filter((item) =>
        physicalFitness.includes(item.label.toLowerCase())
      );

      let commercialLicenseValue = commercial_license?.map((item) =>
        item.toLowerCase()
      );
      commercialLicenseValue = commericalLicense?.filter((item) =>
        commercialLicenseValue.includes(item.label.toLowerCase())
      );

      setInitialValues({
        first_name,
        middle_name,
        last_name,
        mobile_number: mobile,
        email: email,
        date_of_birth: new Date(dob),
        gender,
        address,
        district,
        state,
        social_category,
        pincode,
        level_of_physical_fitness: physicalFitness,
        pwd_category: accessibility_info.is_pwd_category ? "Yes" : "No",
        applicable_category: pwdCategoryData?.filter((item) =>
          accessibility_info?.applied_category
            ?.map((item) => item.toLowerCase())
            .includes(item.value)
        ),
        migrated: migrate_concerns ? "Yes" : "No",
        police_verification: police_verification_concerns ? "Yes" : "No",
        drug_test_verification: drug_test_concerns ? "Yes" : "No",
        assets_owned: assetsOwned,
        government_document: documentsPossess,
        qualification: qualification,
        specilisation_education: qualification_stream,
        education_qualified_year: graduationYear,
        educational_qualification_score: education_score?.trim(),
        certification_of_vocation_or_skill:
          relevent_skill_or_vocation_certification,
        specialisation_skill: certificate_stream?.toLowerCase(),
        skill_qualified_year: certificate_completion_year,
        commercial_license: commercialLicenseValue,
        english_proficiency,
        employment_status: current_employment_status,
        past_work_exp_y: exp_y ? exp_y : 0,
        past_work_exp_m: exp_m ? exp_m : 0,
      });
      setEditForm(true);
    } else {
      setInitialValues({
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile_number: "",
        email: "",
        date_of_birth: null,
        gender: "",
        address: "",
        district: "",
        state: "",
        social_category: "",
        pincode: "",
        city: "",
        marital_status: "",
        dependent_members: "",
        level_of_physical_fitness: [],
        pwd_category: "",
        applicable_category: [],
        migrated: "",
        assets_owned: [],
        government_document: [],
        educational_specialisation_data: [],
        qualification: [],
        qualificationsDetail: [],
        certification: [],
        certificationsDetail: [],
        languages: [],
        languagesDetail: [],
        languagesData: [],
        english_proficiency: "",
        employment_status: "",
        past_work_exp_m: "",
        past_work_exp_y: "",
        experiences: [],
        experienceDetails: [],
        work_preference: "",
        work_made_choice: [],
        engagement_type_preference: "",
      });
      setEditForm(false);
    }
  }, [location.state, setInitialValues, dataById._id, isIncludesValue]);

  // useEffect(() => {
  //   console.log(initialValues.qualification, "qualification");
  //   const getSpecializationEducation = async () => {
  //     console.log(initialValues);
  //     if (!initialValues.qualification.length === 0) return;
  //     let data = await getSpecilizationData("10th pass");
  //     setInitialValues((value) => ({
  //       ...value,
  //       educational_specialisation_data: data,
  //     }));
  //   };
  //   const getSpecializationSkill = async () => {
  //     if (!initialValues.certification_of_vocation_or_skill.trim()) return;
  //     let data = await getSpecilizationData(
  //       initialValues.certification_of_vocation_or_skill
  //     );
  //     setInitialValues((value) => ({ ...value, skillData: data }));
  //   };

  //   getSpecializationEducation();

  // }, [initialValues.qualification]);
  // const addQualificationInformation = (qualificationValue) => {};
  const handleQualificationChange = async (values, newValue, setFieldValue) => {
    setFieldValue("qualification", newValue);
    let specializationData =
      newValue.length > 0
        ? await getSpecilizationData(newValue?.at(-1)?.value?.toLowerCase())
        : [];
    const newQualificationsDetail = newValue?.map((qual) => {
      const existingDetail = values.qualificationsDetail.find(
        (detail) => detail.qualification === qual
      );
      return (
        existingDetail || {
          qualification: qual,
          specialisation: "",
          year_of_completion: "",
          score_type: "",
          score: "",
          medium_of_education: "",
          school_name: "",
          school_address: "",
          specialisation_data: specializationData,
        }
      );
    });
    setFieldValue("qualificationsDetail", newQualificationsDetail);
  };

  const handleCertification = async (newValue, setFieldValue, values) => {
    setFieldValue("certification", newValue);
    let specializationData =
      newValue.length > 0
        ? await getSpecilizationData(newValue?.at(-1)?.label?.toLowerCase())
        : [];

    if (newValue?.at(-1)?.label !== "None") {
      const newCertificationsDetail = newValue?.map((value) => {
        const existingDetail = values.certificationsDetail.find(
          (detail) => detail.certification === value
        );
        return (
          existingDetail || {
            certification: value,
            specialisation: "",
            course_month: "0",
            course_years: "0",
            year_of_completion: "",
            medium_of_education: "",
            school_name: "",
            school_address: "",
            learning_mode: "",
            specializationData: specializationData,
          }
        );
      });
      setFieldValue("certificationsDetail", newCertificationsDetail);
    }
  };

  const workModeData = [
    { id: 1, label: "Field Job", value: "Field Job" },
    { id: 2, label: "Work from Office", value: "Work from Office" },
    { id: 3, label: "Work from Home", value: "Work from Home" },
    { id: 4, label: "Hybrid", value: "Hybrid" },
  ];
  let currentDate = new Date();
  const maxDate = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 18)
  );
  return isIncludesValue ? (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="create-candidates-section" ref={scrollTargetRef}>
          <Container>
            <Box className="create-candidates">
              <Box className="headingPlusBackBtn">
                <BackBtn />
                <h1 className="formHeadings" id="topEle">
                  {editForm ? "Update Candidate" : "Create Candidate"}
                </h1>
              </Box>
              <Box className="create-candidates-form-wrapper">
                <Formik
                  initialValues={initialValues}
                  validationSchema={CreateCandidateValidationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, values, setFieldValue, resetForm }) => (
                    <Form className="create-candidates-form">
                      <Box className="backgroundStyle">
                        <p
                          className="formSubSectionHeading"
                          style={{ padding: "0px 15px" }}
                        >
                          Personal Details
                        </p>
                        <Grid
                          container
                          spacing={2}
                          className="cstmFormGridEmployer"
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="first_name"
                              >
                                {" "}
                                First Name{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="first_name"
                                type="text"
                                placeholder="Enter First Name"
                                name="first_name"
                                className="form-input"
                                size="small"
                              />
                              {errors.first_name && (
                                <Box className="form-error">
                                  {errors.first_name}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="middle_name"
                              >
                                {" "}
                                Middle Name
                              </label>
                              <Field
                                id="middle_name"
                                type="text"
                                placeholder="Enter Middle Name"
                                name="middle_name"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="last_name">
                                Last Name{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="last_name"
                                type="text"
                                placeholder="Enter Last Name"
                                name="last_name"
                                className="form-input"
                              />
                              {errors.last_name && (
                                <Box className="form-error">
                                  {errors.last_name}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="email">
                                Your Email ID
                                {/* <span className="must-fill-fields">*</span> */}
                              </label>

                              <Field
                                id="email"
                                type="text"
                                placeholder="Enter email id"
                                name="email"
                                className="form-input"
                              />
                              {/* {errors.email && (
                                <Box className="form-error">{errors.email}</Box>
                              )} */}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="mobile_number"
                              >
                                Mobile Number
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="mobile_number"
                                type="text"
                                placeholder="Enter Mobile Number"
                                name="mobile_number"
                                className="form-input"
                                maxLength={10}
                                disabled={editForm}
                              />
                              {errors.mobile_number && (
                                <Box className="form-error">
                                  {errors.mobile_number}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="date_of_birth"
                              >
                                Your Date of Birth
                              </label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  className="datePickerGetInput"
                                  value={values.date_of_birth}
                                  onChange={(newValue) => {
                                    setFieldValue("date_of_birth", newValue);
                                  }}
                                  slotProps={{
                                    textField: {
                                      variant: "outlined",
                                      error: false,
                                      size: "medium",
                                    },
                                  }}
                                  // minDate={minDate}
                                  maxDate={maxDate}
                                  format="dd/MM/yyyy"
                                />
                              </LocalizationProvider>
                              {errors.date_of_birth && (
                                <Box className="form-error">
                                  {errors.date_of_birth}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="gender">
                                Gender{" "}
                              </label>
                              <FormControl name="gender" fullWidth size="small">
                                {values.gender === "" && (
                                  <InputLabel shrink={false}>
                                    Select Gender
                                  </InputLabel>
                                )}
                                <Select
                                  placeholder="Select gender"
                                  name="gender"
                                  id="gender"
                                  value={values.gender}
                                  onChange={(event) => {
                                    const selectedGenderValue =
                                      event.target.value;
                                    setFieldValue(
                                      "gender",
                                      selectedGenderValue
                                    );
                                  }}
                                >
                                  {" "}
                                  <MenuItem value={1}>Female</MenuItem>
                                  <MenuItem value={2}>Male</MenuItem>
                                  <MenuItem value={4}>Other</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="pincode">
                                {" "}
                                Pincode{" "}
                              </label>
                              <Field
                                id="pincode"
                                type="text"
                                placeholder="Enter pincode"
                                name="pincode"
                                className="form-input"
                                onBlur={(e) => {
                                  if (e.target.value.length === 6) {
                                    fetchPincodeDetails(
                                      e.target.value,
                                      setFieldValue
                                    );
                                  }
                                }}
                              />
                              {errors.pincode && (
                                <Box className="form-error">
                                  {errors.pincode}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="state">
                                {" "}
                                State{" "}
                              </label>
                              <Field
                                id="state"
                                type="text"
                                placeholder="Enter state"
                                name="state"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="district">
                                Your District
                              </label>
                              <Field
                                id="district"
                                type="text"
                                placeholder="Enter district"
                                name="district"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          {/* <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="city">
                                Your city
                              </label>
                              <Field
                                id="city"
                                type="text"
                                placeholder="Enter city"
                                name="city"
                                className="form-input"
                              />
                            </Box>
                          </Grid> */}

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="address">
                                Complete Address
                              </label>
                              <Field
                                id="address"
                                type="text"
                                placeholder="Enter Address"
                                name="address"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="marital_status"
                              >
                                Your marital status
                              </label>
                              <FormControl
                                name="marital_status"
                                fullWidth
                                size="small"
                              >
                                {values.marital_status === "" && (
                                  <InputLabel shrink={false}>
                                    Select Marital Status
                                  </InputLabel>
                                )}
                                <Select
                                  placeholder="Select marital status"
                                  name="marital_status"
                                  id="marital_status"
                                  value={values.marital_status}
                                  onChange={(event) => {
                                    const selectMaritalStatus =
                                      event.target.value;
                                    setFieldValue(
                                      "marital_status",
                                      selectMaritalStatus
                                    );
                                  }}
                                >
                                  <MenuItem value={1}>Married</MenuItem>
                                  <MenuItem value={2}>Unmarried</MenuItem>
                                  <MenuItem value={3}>Other</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="dependent_members"
                              >
                                No. of dependents in your family
                              </label>
                              <Field
                                id="dependent_members"
                                type="text"
                                placeholder="Enter Number of dependents in your family"
                                name="dependent_members"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={8}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="level_of_physical_fitness"
                              >
                                Select Level of physical fitness
                              </label>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={physicalFitnessData || []}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                value={values.level_of_physical_fitness}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    "level_of_physical_fitness",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select level of physical fitness"
                                  />
                                )}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="pwd_category"
                              >
                                Do you come under Divyangjan (Pwd) Category
                              </label>
                              <div role="group" aria-labelledby="radio-group">
                                <Grid container spacing={2}>
                                  {["Yes", "No"].map((item, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      key={index}
                                    >
                                      <Box className="form-control">
                                        <label
                                          key={index}
                                          className="form-label months_label"
                                          htmlFor={item}
                                        >
                                          <Field
                                            type="radio"
                                            id={item}
                                            name="pwd_category"
                                            value={item}
                                          />
                                          {item}
                                        </label>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                              {errors.pwd_category && (
                                <Box className="form-error">
                                  {errors.pwd_category}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={8} md={8}>
                            {values.pwd_category === "Yes" && (
                              <Box className="form-control">
                                <label
                                  className="form-label"
                                  htmlFor="applicable_category"
                                >
                                  Select accepted Divyangjan categories
                                </label>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  options={pwdCategoryData || []}
                                  getOptionLabel={(option) => option.label}
                                  filterSelectedOptions
                                  value={values.applicable_category}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      "applicable_category",
                                      newValue
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select applicable category"
                                    />
                                  )}
                                />
                              </Box>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="social_category"
                              >
                                Social Category{" "}
                              </label>

                              <FormControl
                                name="social_category"
                                fullWidth
                                size="small"
                              >
                                {values.social_category === "" && (
                                  <InputLabel shrink={false}>
                                    Please select social category
                                  </InputLabel>
                                )}
                                <Select
                                  placeholder="Please enter your blood group"
                                  name="social_category"
                                  id="social_category"
                                  value={values.social_category}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "social_category",
                                      event.target.value
                                    );
                                  }}
                                >
                                  {" "}
                                  <MenuItem value={2}>General</MenuItem>
                                  <MenuItem value={3}>SC</MenuItem>
                                  <MenuItem value={4}>ST</MenuItem>
                                  <MenuItem value={5}>OBC</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="migrated">
                                Are you open to migrate to different state?
                                {/* <span className="must-fill-fields">*</span> */}
                              </label>
                              <div
                                role="group"
                                aria-labelledby="checkbox-group"
                              >
                                <Grid container spacing={2}>
                                  {["Yes", "No"].map((item, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      key={index}
                                    >
                                      <Box className="form-control">
                                        <label
                                          key={index}
                                          className="form-label migrated"
                                          htmlFor={item}
                                        >
                                          <Field
                                            type="radio"
                                            id={item}
                                            name="migrated"
                                            value={item}
                                          />
                                          {item}
                                        </label>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                              {/* {errors.migrated && (
                                <Box className="form-error">
                                  {errors.migrated}
                                </Box>
                              )} */}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="assets_owned"
                              >
                                Select assets owned
                              </label>
                              <Autocomplete
                                name="assets_owned"
                                multiple
                                id="tags-outlined"
                                options={assets_ownedRequired || []}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                value={values.assets_owned}
                                onChange={(event, newValue) => {
                                  setFieldValue("assets_owned", newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select assets owned"
                                  />
                                )}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="government_document"
                              >
                                Select documents available with you
                              </label>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={governmentDocumentsRequired || []}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                value={values.government_document}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    "government_document",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select documents that you posses"
                                  />
                                )}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="backgroundStyle">
                        <p
                          className="formSubSectionHeading"
                          style={{ padding: "0px 15px" }}
                        >
                          Skill Profile
                        </p>
                        <FieldArray name="qualification">
                          {({ push, remove }) => (
                            <Grid
                              container
                              spacing={2}
                              className="cstmFormGridEmployer"
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="qualification"
                                  >
                                    Your Educational Qualifications
                                  </label>
                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={
                                      educationQualificationData?.filter(
                                        (option) =>
                                          !values.qualification.includes(option)
                                      ) || []
                                    }
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    value={values.qualification}
                                    onChange={(event, newValue) => {
                                      handleQualificationChange(
                                        values,
                                        newValue,
                                        setFieldValue
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select your educational qualifications"
                                      />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              {values?.qualificationsDetail.map(
                                (qual, index) => (
                                  <Box
                                    style={{
                                      border: "1px solid #e5e2e2",
                                      margin: "10px",
                                      padding: "10px",
                                      width: "100%",
                                      background: "white",
                                    }}
                                    key={`qualification-${index}`}
                                  >
                                    <h4>{`Qualifications Detail for ${qual?.qualification?.label}`}</h4>
                                    <Grid
                                      container
                                      spacing={2}
                                      className="cstmFormGridEmployer"
                                    >
                                      <Grid item xs={12} sm={6} md={6}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="specialisation"
                                          >
                                            Stream/Specialisation
                                          </label>
                                          <FormControl
                                            name={`qualificationsDetail.${index}.specialisation`}
                                            fullWidth
                                            size="small"
                                          >
                                            {qual.specialisation === "" && (
                                              <InputLabel shrink={false}>
                                                Please select a specialisation
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Please enter specialisation"
                                              name={`qualificationsDetail.${index}.specialisation`}
                                              id={`qualificationsDetail.${index}.specialisation`}
                                              value={qual.specialisation}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `qualificationsDetail.${index}.specialisation`,
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {qual?.specialisation_data?.map(
                                                (item) => (
                                                  <MenuItem value={item.label}>
                                                    {item?.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={6}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor={`qualificationsDetail.${index}.year_of_completion`}
                                          >
                                            Year of Completion
                                          </label>
                                          <FormControl
                                            name="year_of_completion"
                                            fullWidth
                                            size="small"
                                          >
                                            {qual.year_of_completion === "" && (
                                              <InputLabel shrink={false}>
                                                Select Year of completion
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Select year of completion"
                                              name="year_of_completion"
                                              id="year_of_completion"
                                              value={qual?.year_of_completion}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `qualificationsDetail.${index}.year_of_completion`,
                                                  event.target.value?.toString()
                                                );
                                              }}
                                            >
                                              {" "}
                                              {years.map((year) => (
                                                <MenuItem value={year}>
                                                  {year}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={4} md={6}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor={`qualificationsDetail.${index}.score_type`}
                                          >
                                            Score
                                          </label>
                                          <div
                                            role="group"
                                            aria-labelledby="radio-group"
                                          >
                                            <Grid container spacing={2}>
                                              {["Percentage", "CGPA"].map(
                                                (item, indx) => (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    key={index}
                                                  >
                                                    <Box className="form-control">
                                                      <label
                                                        key={indx}
                                                        className="form-label"
                                                        htmlFor={`qualificationsDetail.${index}.score_type`}
                                                      >
                                                        <Field
                                                          type="radio"
                                                          id={`qualificationsDetail.${index}.score_type`}
                                                          name={`qualificationsDetail.${index}.score_type`}
                                                          value={item}
                                                        />
                                                        {item}
                                                      </label>
                                                    </Box>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          </div>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control ">
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <Field
                                              id="score"
                                              type="text"
                                              placeholder="Enter value"
                                              name={`qualificationsDetail.${index}.score`}
                                              className="form-input"
                                              size="small"
                                            />
                                            <span>{qual.score_type}</span>
                                          </Box>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="medium_of_education"
                                          >
                                            Medium of education
                                          </label>

                                          <FormControl
                                            name={`qualificationsDetail.${index}.medium_of_education`}
                                            fullWidth
                                            size="small"
                                          >
                                            {qual.medium_of_education ===
                                              "" && (
                                              <InputLabel shrink={false}>
                                                Please select medium of
                                                education
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Please enter medium of education"
                                              name={`qualificationsDetail.${index}.medium_of_education`}
                                              id={`qualificationsDetail.${index}.medium_of_education`}
                                              value={qual.medium_of_education}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `qualificationsDetail.${index}.medium_of_education`,
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {values.languagesData.map(
                                                (item) => (
                                                  <MenuItem value={item.id}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="school_name"
                                          >
                                            School / College Name
                                          </label>
                                          <Field
                                            id={`qualificationsDetail.${index}.school_name`}
                                            type="text"
                                            placeholder="Enter School / College Name"
                                            name={`qualificationsDetail.${index}.school_name`}
                                            className="form-input"
                                            size="small"
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="school_address"
                                          >
                                            School / College Address
                                          </label>
                                          <Field
                                            id={`qualificationsDetail.${index}.school_address`}
                                            type="text"
                                            placeholder="Enter School / College Address"
                                            name={`qualificationsDetail.${index}.school_address`}
                                            className="form-input"
                                            size="small"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )}
                            </Grid>
                          )}
                        </FieldArray>

                        <FieldArray name="certification">
                          {({ push, remove }) => (
                            <Grid
                              container
                              spacing={2}
                              className="cstmFormGridEmployer"
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="certification"
                                  >
                                    Your Vocational Certificate
                                  </label>
                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={
                                      certificateSpeciliazationData?.filter(
                                        (option) =>
                                          !values?.certification?.includes(
                                            option
                                          )
                                      ) || []
                                    }
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    value={values.certification}
                                    onChange={(event, newValue) => {
                                      handleCertification(
                                        newValue,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select your vocational certificate"
                                      />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              {values?.certificationsDetail.map(
                                (cert, index) => (
                                  <Box
                                    style={{
                                      border: "1px solid #e5e2e2",
                                      margin: "10px",
                                      padding: "10px",
                                      width: "100%",
                                      background: "white",
                                    }}
                                    key={`certifications-${index}`}
                                  >
                                    <h4>{`Certification Detail for ${cert?.certification?.label}`}</h4>
                                    <Grid
                                      container
                                      spacing={2}
                                      className="cstmFormGridEmployer"
                                    >
                                      <Grid item xs={12} sm={6} md={6}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="specialisation"
                                          >
                                            Stream/Specialisation
                                          </label>
                                          <FormControl
                                            name={`qualificationsDetail.${index}.specialisation`}
                                            fullWidth
                                            size="small"
                                          >
                                            {cert.specialisation === "" && (
                                              <InputLabel shrink={false}>
                                                Please select a specialisation
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Please enter specialisation"
                                              name={`certificationsDetail.${index}.specialisation`}
                                              id={`certificationsDetail.${index}.specialisation`}
                                              value={cert.specialisation}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `certificationsDetail.${index}.specialisation`,
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {cert?.specializationData?.map(
                                                (item) => (
                                                  <MenuItem value={item.label}>
                                                    {item?.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={12} md={6}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="course duration"
                                          >
                                            Course Duration
                                          </label>
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              className="form-control "
                                            >
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <Field
                                                  type="text"
                                                  placeholder="Enter value"
                                                  name={`certificationsDetail.${index}.course_years`}
                                                  className="form-input"
                                                  size="small"
                                                />
                                                <span className="form-label">
                                                  Yrs
                                                </span>
                                              </Box>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={6}
                                              className="form-control "
                                            >
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <Field
                                                  type="text"
                                                  placeholder="Enter value"
                                                  name={`certificationsDetail.${index}.course_month`}
                                                  className="form-input"
                                                  size="small"
                                                />
                                                <span className="form-label">
                                                  Mon
                                                </span>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor={`certificationsDetail.${index}.year_of_completion`}
                                          >
                                            Year of Completion
                                          </label>
                                          <FormControl
                                            name="year_of_completion"
                                            fullWidth
                                            size="small"
                                          >
                                            {cert.year_of_completion === "" && (
                                              <InputLabel shrink={false}>
                                                Select Year of completion
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Select year of completion"
                                              name="year_of_completion"
                                              id="year_of_completion"
                                              value={cert?.year_of_completion}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `certificationsDetail.${index}.year_of_completion`,
                                                  event.target.value?.toString()
                                                );
                                              }}
                                            >
                                              {" "}
                                              {years.map((year) => (
                                                <MenuItem value={year}>
                                                  {year}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="medium_of_education"
                                          >
                                            Medium of education
                                          </label>

                                          <FormControl
                                            name={`certificationsDetail.${index}.medium_of_education`}
                                            fullWidth
                                            size="small"
                                          >
                                            {cert.medium_of_education ===
                                              "" && (
                                              <InputLabel shrink={false}>
                                                Please select medium of
                                                education
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Please enter medium of education"
                                              name={`certificationsDetail.${index}.medium_of_education`}
                                              id={`certificationsDetail.${index}.medium_of_education`}
                                              value={cert.medium_of_education}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `certificationsDetail.${index}.medium_of_education`,
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {values.languagesData.map(
                                                (item) => (
                                                  <MenuItem value={item.id}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="school_name"
                                          >
                                            School / College Name
                                          </label>
                                          <Field
                                            id={`certificationsDetail.${index}.school_name`}
                                            type="text"
                                            placeholder="Enter School Name"
                                            name={`certificationsDetail.${index}.school_name`}
                                            className="form-input"
                                            size="small"
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor="school_address"
                                          >
                                            School/ College Address
                                          </label>
                                          <Field
                                            id={`certificationsDetail.${index}.school_address`}
                                            type="text"
                                            placeholder="Enter School Address"
                                            name={`certificationsDetail.${index}.school_address`}
                                            className="form-input"
                                            size="small"
                                          />
                                        </Box>
                                      </Grid>

                                      <Grid item xs={12} sm={8} md={8}>
                                        <Box className="form-control">
                                          <label
                                            className="form-label"
                                            htmlFor={`certificationsDetail.${index}.learning_mode`}
                                          >
                                            Learning Mode
                                          </label>
                                          <FormControl
                                            name={`certificationsDetail.${index}.learning_mode`}
                                            fullWidth
                                            size="small"
                                          >
                                            {cert.learning_mode === "" && (
                                              <InputLabel shrink={false}>
                                                Please select learning mode
                                              </InputLabel>
                                            )}
                                            <Select
                                              placeholder="Please enter learning mode"
                                              name={`certificationsDetail.${index}.learning_mode`}
                                              id={`certificationsDetail.${index}.learning_mode`}
                                              value={cert.learning_mode}
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `certificationsDetail.${index}.learning_mode`,
                                                  event.target.value
                                                );
                                              }}
                                            >
                                              {[
                                                { id: 1, label: "Classroom" },
                                                {
                                                  id: 2,
                                                  label:
                                                    "E-learning/correspondance",
                                                },
                                                {
                                                  id: 3,
                                                  label: "Hybrid",
                                                },
                                              ].map((item) => (
                                                <MenuItem value={item.id}>
                                                  {item.label}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )}
                            </Grid>
                          )}
                        </FieldArray>

                        <FieldArray name="languages">
                          {({ push, remove }) => (
                            <Grid
                              container
                              spacing={2}
                              className="cstmFormGridEmployer"
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="languages"
                                  >
                                    Select language known
                                  </label>
                                  <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={
                                      initialValues.languagesData?.filter(
                                        (option) =>
                                          !values?.language?.includes(option)
                                      ) || []
                                    }
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    value={values.languages}
                                    onChange={(event, newValue) => {
                                      setFieldValue("languages", newValue);
                                      const newCertificationsDetail =
                                        newValue?.map((value) => {
                                          const existingDetail =
                                            values.certificationsDetail.find(
                                              (detail) =>
                                                detail.certification === value
                                            );
                                          return (
                                            existingDetail || {
                                              language: value,
                                              can_speak: "",
                                              can_write: "",
                                              can_read: "",
                                            }
                                          );
                                        });
                                      setFieldValue(
                                        "languagesDetail",
                                        newCertificationsDetail
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select languages that you have known"
                                      />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              {values?.languagesDetail.map((lang, index) => (
                                <Box
                                  style={{
                                    border: "1px solid #e5e2e2",
                                    margin: "10px",
                                    padding: "10px",
                                    width: "100%",
                                    background: "white",
                                  }}
                                  key={`languages-${index}`}
                                >
                                  <h4>{`Proficiency for ${lang?.language?.label}`}</h4>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="cstmFormGridEmployer"
                                  >
                                    <Grid item xs={12} sm={4} md={6}>
                                      <Box className="form-control">
                                        <label
                                          className="form-label"
                                          htmlFor={`languagesDetail.${index}.can_speak`}
                                        >
                                          Can Speak ?
                                        </label>
                                        <div
                                          role="group"
                                          aria-labelledby="radio-group"
                                        >
                                          <Grid container spacing={2}>
                                            {["Yes", "No"].map((item, indx) => (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={index}
                                              >
                                                <Box className="form-control">
                                                  <label
                                                    key={indx}
                                                    className="form-label"
                                                    htmlFor={`languagesDetail.${index}.can_speak`}
                                                  >
                                                    <Field
                                                      type="radio"
                                                      id={`languagesDetail.${index}.can_speak`}
                                                      name={`languagesDetail.${index}.can_speak`}
                                                      value={item}
                                                    />
                                                    {item}
                                                  </label>
                                                </Box>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={6}>
                                      <Box className="form-control">
                                        <label
                                          className="form-label"
                                          htmlFor={`languagesDetail.${index}.can_read`}
                                        >
                                          Can Read ?
                                        </label>
                                        <div
                                          role="group"
                                          aria-labelledby="radio-group"
                                        >
                                          <Grid container spacing={2}>
                                            {["Yes", "No"].map((item, indx) => (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={index}
                                              >
                                                <Box className="form-control">
                                                  <label
                                                    key={indx}
                                                    className="form-label"
                                                    htmlFor={`languagesDetail.${index}.can_read`}
                                                  >
                                                    <Field
                                                      type="radio"
                                                      id={`languagesDetail.${index}.can_read`}
                                                      name={`languagesDetail.${index}.can_read`}
                                                      value={item}
                                                    />
                                                    {item}
                                                  </label>
                                                </Box>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={6}>
                                      <Box className="form-control">
                                        <label
                                          className="form-label"
                                          htmlFor={`languagesDetail.${index}.can_write`}
                                        >
                                          Can Write ?
                                        </label>
                                        <div
                                          role="group"
                                          aria-labelledby="radio-group"
                                        >
                                          <Grid container spacing={2}>
                                            {["Yes", "No"].map((item, indx) => (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={index}
                                              >
                                                <Box className="form-control">
                                                  <label
                                                    key={indx}
                                                    className="form-label"
                                                    htmlFor={`languagesDetail.${index}.can_write`}
                                                  >
                                                    <Field
                                                      type="radio"
                                                      id={`languagesDetail.${index}.can_write`}
                                                      name={`languagesDetail.${index}.can_write`}
                                                      value={item}
                                                    />
                                                    {item}
                                                  </label>
                                                </Box>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}
                            </Grid>
                          )}
                        </FieldArray>

                        <Grid item xs={12} sm={4} md={6}>
                          <Box
                            className="form-control"
                            style={{ padding: "0px 15px" }}
                          >
                            <label
                              className="form-label"
                              htmlFor={`english_proficiency`}
                            >
                              English language Proficiency
                            </label>
                            <FormControl
                              name="english_proficiency"
                              fullWidth
                              size="small"
                            >
                              {values.english_proficiency === "" && (
                                <InputLabel shrink={false}>
                                  please select a langauge
                                </InputLabel>
                              )}
                              <Select
                                name="english_proficiency"
                                id="english_proficiency"
                                value={values.english_proficiency}
                                onChange={(event) => {
                                  setFieldValue(
                                    `english_proficiency`,
                                    event.target.value
                                  );
                                }}
                              >
                                {[
                                  { id: 0, label: "No English" },
                                  { id: 2, label: "Thoda English" },
                                  { id: 3, label: "Good English" },
                                  { id: 4, label: "Fluent" },
                                ].map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Box>
                      <Box className="backgroundStyle">
                        <p
                          className="formSubSectionHeading"
                          style={{ padding: "0px 15px" }}
                        >
                          Work Profile{" "}
                        </p>

                        <Grid
                          container
                          spacing={2}
                          className="cstmFormGridEmployer"
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="employment_status"
                              >
                                Employment Status{" "}
                              </label>
                              <FormControl
                                name="employment_status"
                                fullWidth
                                size="small"
                              >
                                {values.employment_status === "" && (
                                  <InputLabel shrink={false}>
                                    Please select employment status
                                  </InputLabel>
                                )}
                                <Select
                                  name="employment_status"
                                  id="employment_status"
                                  value={values.employment_status}
                                  onChange={(event) => {
                                    if (event.target.value === "fresher") {
                                      setInitialValues({
                                        ...values,
                                        past_work_exp_y: 0,
                                        past_work_exp_m: 0,
                                        employment_status: event.target.value,
                                      });
                                    } else {
                                      setInitialValues({
                                        ...values,
                                        past_work_exp_y: "",
                                        past_work_exp_m: "",
                                        employment_status: event.target.value,
                                      });
                                    }
                                  }}
                                >
                                  {employementStatus.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="past_work_exp_y"
                              >
                                {" "}
                                Total past experience (in Years)
                              </label>
                              <FormControl
                                name="past_work_exp_y"
                                fullWidth
                                size="small"
                              >
                                <Field
                                  id="past_work_exp_y"
                                  type="text"
                                  placeholder="Enter experience in years"
                                  name="past_work_exp_y"
                                  className="form-input"
                                  size="small"
                                  disabled={
                                    values.employment_status === "fresher"
                                  }
                                  value={
                                    values.employment_status === "fresher"
                                      ? 0
                                      : values.past_work_exp_y
                                  }
                                />
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="past_work_exp_m"
                              >
                                {" "}
                                Total past experience (in months)
                              </label>
                              <FormControl
                                name="past_work_exp_m"
                                fullWidth
                                size="small"
                              >
                                <Field
                                  id="past_work_exp_m"
                                  type="text"
                                  placeholder="Enter experience in months"
                                  name="past_work_exp_m"
                                  className="form-input"
                                  size="small"
                                  disabled={
                                    values.employment_status === "fresher"
                                  }
                                  value={
                                    values.employment_status === "fresher"
                                      ? 0
                                      : values.past_work_exp_m
                                  }
                                />
                              </FormControl>
                              {errors.past_work_exp_m && (
                                <Box className="form-error">
                                  {errors.past_work_exp_m}
                                </Box>
                              )}
                            </Box>
                          </Grid>
                          <FieldArray name="experience">
                            {({ push, remove }) => (
                              <Grid
                                container
                                spacing={2}
                                className="cstmFormGridEmployer"
                              >
                                <Grid
                                  items
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ padding: "15px", marginTop: "12px" }}
                                >
                                  <Button
                                    onClick={() => {
                                      let experience_number =
                                        values.experiences.length + 1;
                                      let experiences = [
                                        ...values.experiences,
                                        experience_number,
                                      ];
                                      setFieldValue("experiences", experiences);

                                      const experienceDetail = experiences?.map(
                                        (exp) => {
                                          const existingDetail =
                                            values.experienceDetails.find(
                                              (detail) =>
                                                detail.experience === exp
                                            );

                                          return (
                                            existingDetail || {
                                              experience: exp,
                                              job_role: "",
                                              employer_company: "",
                                              employer_address: "",
                                              employement_starting_time: "",
                                              employement_ending_time: "",
                                              engagement_type: "",
                                              currently_working: "",
                                              salary: "",
                                            }
                                          );
                                        }
                                      );

                                      setFieldValue(
                                        "experienceDetails",
                                        experienceDetail
                                      );
                                    }}
                                  >
                                    Add Experience +{" "}
                                  </Button>
                                </Grid>
                                {values?.experienceDetails?.map(
                                  (exp, index) => (
                                    <Box
                                      style={{
                                        border: "1px solid #e5e2e2",
                                        margin: "10px",
                                        padding: "10px",
                                        width: "100%",
                                        background: "white",
                                      }}
                                      key={`experience-${index}`}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <h4>{`Experience ${exp?.experience}`}</h4>
                                        <Button
                                          onClick={() => {
                                            let experiencesDetail =
                                              values.experienceDetails.filter(
                                                (item) =>
                                                  item.experience !==
                                                  exp.experience
                                              );
                                            let experiences =
                                              values.experiences.filter(
                                                (item) =>
                                                  exp.experience === item
                                              );
                                            setFieldValue(
                                              "experiences",
                                              experiences
                                            );
                                            setFieldValue(
                                              "experienceDetails",
                                              experiencesDetail
                                            );
                                          }}
                                        >
                                          Remove Experience -{" "}
                                        </Button>
                                      </div>
                                      <Grid
                                        container
                                        spacing={2}
                                        className="cstmFormGridEmployer"
                                      >
                                        <Grid item xs={12} sm={6} md={4}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="job_role"
                                            >
                                              Job Role
                                            </label>
                                            <Field
                                              id={`experienceDetails.${index}.job_role`}
                                              type="text"
                                              placeholder="Enter job role"
                                              name={`experienceDetails.${index}.job_role`}
                                              className="form-input"
                                              size="small"
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="employer_company"
                                            >
                                              Company Name
                                            </label>
                                            <Field
                                              id={`experienceDetails.${index}.employer_company`}
                                              type="text"
                                              placeholder="Enter company Name"
                                              name={`experienceDetails.${index}.employer_company`}
                                              className="form-input"
                                              size="small"
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="employer_address"
                                            >
                                              Company address
                                            </label>
                                            <Field
                                              id={`experienceDetails.${index}.employer_address`}
                                              type="text"
                                              placeholder="Enter official company address"
                                              name={`experienceDetails.${index}.employer_address`}
                                              className="form-input"
                                              size="small"
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="employement Period"
                                            >
                                              Employment Period
                                            </label>
                                            <Box>
                                              <Field
                                                type="checkbox"
                                                name={`experienceDetails.${index}.currently_working`}
                                              />
                                              <span>
                                                Currently working here
                                              </span>
                                            </Box>

                                            <Box
                                              style={{
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <DatePicker
                                                  className="datePickerGetInput"
                                                  value={
                                                    exp.employement_starting_time
                                                  }
                                                  onChange={(newValue) => {
                                                    setFieldValue(
                                                      `experienceDetails.${index}.employement_starting_time`,
                                                      newValue
                                                    );
                                                  }}
                                                  slotProps={{
                                                    textField: {
                                                      variant: "outlined",
                                                      error: false,
                                                      size: "medium",
                                                    },
                                                  }}
                                                />
                                              </LocalizationProvider>

                                              <span>till</span>
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <DatePicker
                                                  className="datePickerGetInput"
                                                  value={
                                                    exp.employement_ending_time
                                                  }
                                                  onChange={(newValue) => {
                                                    setFieldValue(
                                                      `experienceDetails.${index}.employement_ending_time`,
                                                      newValue
                                                    );
                                                  }}
                                                  slotProps={{
                                                    textField: {
                                                      variant: "outlined",
                                                      error: false,
                                                      size: "medium",
                                                    },
                                                  }}
                                                  disabled={
                                                    exp.currently_working
                                                  }
                                                />
                                              </LocalizationProvider>
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="engagement_type"
                                            >
                                              Engagement Type
                                            </label>

                                            <FormControl fullWidth size="small">
                                              {exp.engagement_type === "" && (
                                                <InputLabel shrink={false}>
                                                  Select engagement type
                                                </InputLabel>
                                              )}
                                              <Select
                                                name={`experienceDetails.${index}.engagement_type`}
                                                id={`experienceDetails.${index}.engagement_type`}
                                                value={exp.engagement_type}
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    `experienceDetails.${index}.engagement_type`,
                                                    event.target.value
                                                  );
                                                }}
                                              >
                                                {["Full Time", "Part Time"].map(
                                                  (item, index) => (
                                                    <MenuItem
                                                      key={index}
                                                      value={item}
                                                    >
                                                      {item}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="salary"
                                            >
                                              Salary per month
                                            </label>
                                            <Field
                                              id={`experienceDetails.${index}.salary`}
                                              type="text"
                                              placeholder="Enter salary per month"
                                              name={`experienceDetails.${index}.salary`}
                                              className="form-input"
                                              size="small"
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )
                                )}
                              </Grid>
                            )}
                          </FieldArray>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="work_preference"
                              >
                                Work location preference
                              </label>

                              <FormControl fullWidth size="small">
                                {values.work_preference === "" && (
                                  <InputLabel shrink={false}>
                                    Select work location preference
                                  </InputLabel>
                                )}
                                <Select
                                  name="work_preference"
                                  id="work_preference"
                                  value={values.work_preference}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "work_preference",
                                      event.target.value
                                    );
                                  }}
                                >
                                  {[
                                    { id: 1, label: "Pass" },
                                    { id: 2, label: "Thoda Dur" },
                                    { id: 3, label: "Bahut Dur" },
                                  ].map((item, index) => (
                                    <MenuItem key={index} value={item?.id}>
                                      {item?.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="work_made_choice"
                              >
                                Select Work mode choice
                              </label>
                              <FormControl
                                name="work_made_choice"
                                fullWidth
                                size="small"
                              >
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  options={workModeData || []}
                                  getOptionLabel={(option) => option.label}
                                  filterSelectedOptions
                                  value={values.work_made_choice}
                                  onChange={(event, newValue) => {
                                    setFieldValue("work_made_choice", newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select work mode choice"
                                    />
                                  )}
                                />
                                {/* <Select
                                  placeholder="Select work made choice"
                                  name="work_made_choice"
                                  id="work_made_choice"
                                  value={values.work_made_choice}
                                  onChange={(event) => {
                                    const selectWorkMadeChoice =
                                      event.target.value;
                                    setFieldValue(
                                      "work_made_choice",
                                      selectWorkMadeChoice
                                    );
                                  }}
                                >
                                  <MenuItem value={1}>Field Job</MenuItem>
                                  <MenuItem value={2}>
                                    Work from Office
                                  </MenuItem>
                                  <MenuItem value={3}>Work from Home</MenuItem>
                                  <MenuItem value={4}>Hybrid</MenuItem>
                                </Select> */}
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="engagement_type_preference"
                              >
                                Engagement Type Preference
                              </label>
                              <FormControl
                                name="engagement_type_preference"
                                fullWidth
                                size="small"
                              >
                                {values.engagement_type_preference === "" && (
                                  <InputLabel shrink={false}>
                                    Select engagement type preference
                                  </InputLabel>
                                )}
                                <Select
                                  name="engagement_type_preference"
                                  id="engagement_type_preference"
                                  value={values.engagement_type_preference}
                                  onChange={(event) => {
                                    const selectWorkPreference =
                                      event.target.value;
                                    setFieldValue(
                                      "engagement_type_preference",
                                      selectWorkPreference
                                    );
                                  }}
                                >
                                  <MenuItem value={1}>Full Time</MenuItem>
                                  <MenuItem value={2}>Part Time</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="form-button-section">
                        <Link to="/candidate">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              resetForm();
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </Link>

                        <Button
                          className="form-button"
                          variant="contained"
                          type="submit"
                          color="success"
                          disabled={formSubmitted}
                        >
                          {editForm ? "Update" : "Create"}
                        </Button>
                      </Box>
                      <FocusError handleSnackBar={handleSnackBar} />
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};
export default CreateCandidates;
