import * as yup from "yup";

const validateImageFile = (file) => {
  console.log(file, " this is file");
  const allowedExtensions = ["jpg", "jpeg", "png", "svg"]; // Add more extensions as needed
  console.log(
    file[0]?.name?.split(".").pop().toLowerCase(),
    " this is file type"
  );
  let fileExtension;
  if (file[0]?.name) {
    fileExtension = file[0]?.name?.split(".").pop().toLowerCase();
  } else {
    fileExtension = file?.split(".").pop().toLowerCase();
    console.log(
      file?.split(".").pop().toLowerCase(),
      " if file[0] is undefined"
    );
  }
  console.log(
    allowedExtensions.includes(fileExtension),
    " allowedExtensions.includes(fileExtension)"
  );
  return allowedExtensions.includes(fileExtension);
};

export const emailValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email Address is required")
    .email("Please enter a valid email Address"),
});

export const currentPasswordNewPasswordValidation = yup.object().shape({
  currentPassword: yup.string().trim().required("Current password is required"),
  newPassword: yup
    .string()
    .trim()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

export const loginEmailAndPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid Email"),
  password: yup.string().trim().required("Password is required"),
  rememberMe: yup.boolean(),
});

//Employer Login Validation
export const userValidationSchema = yup.object().shape({
  full_name: yup.string().required("Name is required"),
  user_type: yup.string().required("User Type is required"),
  email_address: yup
    .string()
    .required("Email Address is required")
    .email("Please enter a valid email Address"),
  password: yup.string().required("Password is required"),
});

export const MobileNumberValidationSchema = yup.object().shape({
  mobile_number: yup.string().required("Mobile Number is required"),
});

const tradeSchema = yup.object().shape({
  id: yup.string().required(),
  label: yup.string().required(),
});

export const CreatePartnerValidationSchema = yup.object().shape({
  name_of_institute: yup
    .string()
    .matches(
      /^[a-zA-Z\s]*$/,
      "Name of institute must contain only alphabetic characters"
    )
    .trim()
    .required("Name of Institute is required"),
  contact_number: yup
    .string()
    .required("Contact Number is required")
    .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Contact Number", (value) => {
      return value ? true : false;
    }),
  contact_email_address: yup
    .string()
    .email("Invalid email format")

    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Email Address is required"),
  alternate_contact_number: yup
    .string()
    .trim()
    .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Contact Number", (value) => {
      if (!value) return true;
      return value ? true : false;
    }),
  alternate_email_address: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .optional("Email Address is required"),
  trade: yup
    .array()
    .of(tradeSchema)
    .required("Training Trade is required")
    .min(1, "At least one trade is required"),
  institute_type: yup.string().trim().required("Institute Type is required"),
  contact_person_first_name: yup
    .string()
    .matches(
      /^[a-zA-Z\s]*$/,
      "First name must contain only alphabetic characters"
    )
    .trim()
    .required("Please enter first name"),
  contact_person_middle_name: yup
    .string()
    .matches(
      /^[a-zA-Z\s]*$/,
      "Middle name must contain only alphabetic characters"
    )
    .trim()
    .optional(),
  contact_person_last_name: yup
    .string()
    .matches(
      /^[a-zA-Z\s]*$/,
      "Last name must contain only alphabetic characters"
    )
    .trim()
    .required("Please enter last name"),
  address: yup.string().trim().required("Address is required"),
  pincode: yup
    .string()
    .trim()
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  city: yup.string().trim().required("City is required"),
  state: yup.string().trim().required("State is required"),
});

const candidateAutoCompleteSchema = yup.object().shape({
  id: yup.string().required(),
  label: yup.string().required(),
  value: yup.string().required(),
});
export const CreateCandidateValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(
      /^[a-zA-Z\s]*$/,
      "First name must contain only alphabetic characters"
    )
    .trim()
    .required("Please enter first name"),
  // middle_name: yup.string().matches(/^[a-zA-Z\s]*$/, "Middle name must contain only alphabetic characters").trim().optional(),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(
      /^[a-zA-Z\s]*$/,
      "Last name must contain only alphabetic characters"
    )
    .trim()
    .required("Please enter last name"),
  // gender: yup.string().required('Gender is required'),
  mobile_number: yup
    .string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Mobile Number", (value) => {
      return value ? true : false;
    }),
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .optional(),
  date_of_birth: yup
    .date()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "Date of birth must be at least 18 years before the current date"
    )
    .nullable(),
  // address: yup.string().required("Address is required"),
  // district: yup.string().required("District is required"),
  // state: yup.string().matches(/^[a-zA-Z\s]*$/, "State must contain only alphabetic characters").required("State is required"),
  // social_category: yup.string().required("Social category is required"),
  pincode: yup
    .string()
    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits")
    .optional(),
  // pwd_category: yup.string().required("This field is required"),
  // migrated: yup.string().required("This field is required"),
  // police_verification: yup.string().required("This field is required"),
  // drug_test_verification: yup.string().required("This field is required"),
  // assets_owned: yup.array()
  //   .of(candidateAutoCompleteSchema).min(1, "This field is required").required("Assets owned is required"),
  // government_document: yup.array()
  //   .of(candidateAutoCompleteSchema).min(1, "This field is required").required("Government document is required"),
  // qualification: yup.string().trim().required("Highest Qualification is required"),
  // specilisation_education: yup.string().trim().required("Specialisation education is required"),
  // education_qualified_year: yup.string().trim().required("Education qualified year is required"),
  // educational_qualification_score: yup.string().trim().required("Educational qualification score is required"),
  // certification_of_vocation_or_skill: yup.string().trim().required("Certification of vocation or skill is required"),
  // specialisation_skill: yup.string().when('certification_of_vocation_or_skill', {
  //   is: (value) => value !== 'none',
  //   then: () => yup.string().trim().required("Specialisation skill is required"),
  // }),
  // skill_qualified_year: yup.string().when('certification_of_vocation_or_skill', {
  //   is: (value) => value !== 'none',
  //   then: () => yup.string().trim().required("Skill qualified year is required"),
  // }),
  // english_proficiency: yup.string().required("English proficiency is required"),
  // employment_status: yup.string().required("Employment status is required"),
  // past_work_exp_y: yup.number().when('employment_status', {
  //   is: (value) => value !== 'fresher',
  //   then: () => yup.number().typeError("This field should contain only digits")
  //     .required("This field is required")
  // }),
  // past_work_exp_m: yup.number().when('employement_status', {
  //   is: (value) => value !== 'fresher',
  //   then: () => yup.number().typeError("This field should contain only digits")
  //     .max(11, 'This field value should be less than 12').required("This field is required")
  // }),
});

export const CandidateKycValidationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  state: yup.string().required("State is required"),
  jobshayakName: yup.string().required("Jobsahayak is required"),
});

export const CreateEmployerValidationSchema = yup.object().shape({
  companyName: yup.string().trim().required("Company Name is required"),
  firstName: yup.string().trim().required("First Name is required"),
  // middleName: yup.string().trim().required('Middle Name is required'),
  lastName: yup.string().trim().required("Last Name is required"),
  phone: yup
    .string()
    .required("Contact Number is required")
    .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Contact Number", (value) => {
      return value ? true : false;
    }),
  // email: yup.string().email('Invalid email format').required('Email Name is required'),

  // recruitOnBehalfOfClients: yup.string().trim().required('Dashboard Type is required'),
  fullAddress: yup.string().trim().required("Company Address is required"),
  businessRoles: yup.string().trim().required("Designation is required"),
  sectorType: yup.object().required("Company Sector is required"),
  businessCategory: yup
    .string()
    .trim()
    .required("Business Category is required"),
  // usertype: yup.string().trim().required('Dashboard Type is required'),

  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Email Address is required"),
  companyEmail: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Company Email Address is required"),

  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup
    .string()
    .required("pincode is required")
    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits"),
});
export const UpdateEmployerValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "First name should only contain alphabets and spaces"
    )
    .required("First Name is required"),
  middle_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Middle name should only contain alphabets and spaces"
    )
    .optional(),
  last_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Last name should only contain alphabets and spaces"
    )
    .required("Last Name is required"),
  company_name: yup.string().required("Company Name is required"),
  city: yup.string().required("City is required"),
  // brand_name: yup.string().required("Brand name is required"),
  state: yup.string().required("State is required"),
  // password: yup.string().required("Password is required"),
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .required("pincode is required")
    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits"),
  district: yup.string().trim().required("District is required"),
});

export const JobSahayakValidationSchema = yup.object().shape({
  first_name: yup.string().trim().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  mobile_number: yup
    .string()
    .required("Phone Number is required")
    .matches(/^[0-9]{10}$/, "Phone Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Phone Number", (value) => {
      return value ? true : false;
    }),
  email_address: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .optional(),
  emgergency_contact_person_name: yup
    .string()
    .required("Emergency Contact Person Name is required"),
  emgergency_contact_mobile: yup
    .string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Mobile Number", (value) => {
      return value ? true : false;
    }),
});

export const ValidationSchemaForJobConfiguration = yup.object().shape({
  jobTitleInEnglish: yup
    .string()
    .trim()
    .required("Please enter a valid Job Title"),
  jobTitleInHindi: yup
    .string()
    .trim()
    .required("Please enter a valid Job Title"),
  jobTitleInHinglish: yup
    .string()
    .trim()
    .required("Please enter a valid Job Title"),
  svgPngImg: yup
    .mixed()
    .required("Please upload an Image")
    .test("is-valid-type", "Not a valid image type", (value) => {
      if (!value) return true;
      return validateImageFile(value);
    }),
});

export const validationSchemaForJobSector = yup.object().shape({
  jobSector: yup.string().trim().required("Please enter a Job Sector"),
});

export const validationSchemaForDepartment = yup.object().shape({
  department: yup.string().trim().required("Please enter a Job Sector"),
});

export const QrValidataionSchema = yup.object().shape({
  template_name: yup.string().trim().required("Please select a template"),
  template_size: yup.string().trim().required("Please select a template size"),
  no_of_qr_code: yup
    .number()
    .typeError("Number of Qr code should be a number")
    .required("Please enter no. of qr code")
    .min(5, "No. of QR code must be at least 5")
    .test({
      name: "multipleOfFive",
      message: "No. of QR code must be a multiple of 5",
      test: function (value) {
        return value % 5 === 0;
      },
    })
    .test({
      name: "greaterOrEqual",
      message: "No. of QR code must be greater than or equal to batch size",
      test: function (value) {
        const batchSize = this.resolve(yup.ref("batch_size"));
        if (batchSize !== undefined) {
          return value >= batchSize;
        }
        return true;
      },
    }),
  batch_size: yup
    .number("")
    .typeError("Batch size must be a number")
    .required("Please enter a batch size")
    .positive("Batch size must be greater than 0")
    .test({
      name: "multipleOfFive",
      message: "Batch Size must be a multiple of 5",
      test: function (value) {
        return value % 5 === 0;
      },
    }),
});

export const assignUserSchema = yup.object().shape({
  username: yup.string().trim().required("User name is required"),
});

export const subadminValidationSchema = yup.object().shape({
  first_name: yup.string().trim().required("First Name is required"),
  last_name: yup.string().trim().required("Last Name is required"),
  access_role: yup.string().trim().required("Access Role is required"),
  email_id: yup
    .string()
    .trim()
    .required("Email Address is required")
    .test("Invalid email address provided.", function (value) {
      if (!value) return false;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailRegex.test(value)) return true;
      return false;
    }),
  password: yup
    .string()
    .trim()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const editSubadminValidationSchema = yup.object().shape({
  first_name: yup.string().trim().required("First Name is required"),

  last_name: yup.string().trim().required("Last Name is required"),
  access_role: yup.string().trim().required("Access Role is required"),
  email_id: yup
    .string()
    .trim()
    .required("Email Address is required")
    .test("Invalid email address provided.", function (value) {
      if (!value) return false;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailRegex.test(value)) return true;
      return false;
    }),
});

export const CreateAnnouncementValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  message: yup.string().required("Message is required"),
  bgColor: yup.string().required("Background color is required"),
});

export const UpdateAnnouncementValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  message: yup.string().required("Message is required"),
  bgColor: yup.string().required("Background color is required"),
});

export const CofigRolesValidationSchema = yup.object().shape({
  role_name: yup.string().required("Role Name is required"),
});

export const validationForCreateTask = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  // source_location: yup.string().trim().required("Source Location is required"),
  destination_location: yup
    .string()
    .trim()
    .required("Destination Location is required"),
  date_of_arrival: yup
    .date()
    .required("Date Of Arrival is required")
    .min(new Date(), "Date of arrival should not be a past date"),
});

export const validationForAssignTask = yup.object().shape({
  // state: yup.string().trim().required("State is required"),
  // city: yup.string().trim().required("City is required"),
  // jobshayak_name: yup.required("Job Shayak is required"),
});

// export const CreateWhatsAppCampaignValidationSchema = yup.object().shape({
//   campaignName: yup.string().trim().required("Campaign Name is required"),
//   templateId: yup.string().trim().required("Template ID is required"),
//   campaignType: yup.string().trim().required("Campaign Type is required"),
//   csvFile: yup.string().trim().required("csv File is required"),
//   templateLanguage: yup
//     .string()
//     .trim()
//     .required("Template Language is required"),
//   // sendLater: yup.boolean(),
//   // date: yup
//   //   .date()
//   //   .nullable()
//   //   .when("sendLater", {
//   //     is: true,
//   //     then: yup.date().required("Date is required"),
//   //   }),
//   // time: yup
//   //   .string()
//   //   .trim()
//   //   .when("sendLater", {
//   //     is: true,
//   //     then: yup.string().required("Time is required"),
//   //   }),
// });

export const CreateNotificationValidationSchema1 = yup.object().shape({
  Type: yup.string().trim().required("Type is required"),
  radius: yup.string().trim().required("Radius is required"),
  JobId: yup
    .string()
    .trim()
    .when("Type", {
      is: (value) => value !== "Pincode",
      then: (schema) => schema.required("Job Id is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  pincode: yup
    .string()
    .trim()
    .when("Type", {
      is: "Pincode",
      then: (schema) =>
        schema
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const CreateNotificationValidationSchema2 = yup.object().shape({
  body: yup.string().trim().required("Message is required"),
  title: yup.string().trim().required("Title is required"),
  schedule: yup.boolean().notRequired(),
  scheduledTime: yup
    .string()
    .test(
      "conditional-require-and-future-time",
      "Schedule Time is required and must be in the future when scheduling is enabled",
      function (value) {
        if (!this.parent.schedule) {
          return true; // No validation needed if scheduling is not enabled
        }

        if (!value) {
          return false; // Fail validation if no time is provided when scheduling is enabled
        }

        const scheduledDate = new Date(value);
        const currentDate = new Date();

        return scheduledDate > currentDate; // Ensure the scheduled time is in the future
      }
    ),
});

export const CreateSmsCampaignValidationSchema = yup.object().shape({
  campaignName: yup.string().trim().required("Campaign Name is required"),
  templateId: yup.string().trim().required("Template ID is required"),
  senderHeader: yup.string().trim().required("Sender Header is required"),
  campaignType: yup.string().trim().required("Campaign Type is required"),
  csvFile: yup.string().trim().required("csv File is required"),
  bodyTextContent: yup.string().trim().required("Body is required"),
});
export const CreateEmailCampaignValidationSchema = yup.object().shape({
  campaignName: yup.string().trim().required("Campaign Name is required"),
  bodyTextContent: yup.string().trim().required("Template is required"),
  campaignType: yup.string().trim().required("Campaign Type is required"),
  csvFile: yup.string().trim().required("csv File is required"),
  email_from: yup.string().trim().required("Email from is required"),
});

export const CreateJSKValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "First name should only contain alphabets and spaces"
    )
    .required("First name is required"),
  middle_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Middle name should only contain alphabets and spaces"
    )
    .optional(),
  last_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Last name should only contain alphabets and spaces"
    )
    .required("Last name is required"),
  date_of_birth: yup
    .date()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "Date of Birth is not valid"
    )
    .required("Date of birth is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    ),
  registered_mobile_number: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
  communication_perference: yup
    .array()
    .of(tradeSchema)
    .required("Communication perference is required")
    .min(1, "At least one communication perference is required"),
  shop_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Shop name should only contain alphabets and spaces"
    )
    .required("Shop name is required"),
  shop_address: yup.string().trim().required("Shop address is required"),
  shop_location: yup.string().trim().required("Shop location is required"),
  gst_details: yup.string().trim().required("GST details are required"),
  house_number: yup.string().trim().required("House number is required"),
  pincode: yup
    .string()
    .trim()
    .matches(/^[1-9][0-9]{5}$/, "Invalid pincode")
    .required("Pincode is required"),
  state: yup.string().trim().required("State is required"),
  bank_details_option: yup
    .string()
    .trim()
    .required("Bank details are required"),
  account_number: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () => yup.string().trim().required("Account number is required"),
  }),
  confirm_account_number: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () =>
      yup.string().trim().required("Confirm account number is required"),
  }),
  ifsc_code: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () => yup.string().trim().required("IFSC is required"),
  }),
  account_holder_name: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () =>
      yup
        .string()
        .trim()
        .matches(
          /^[A-Za-z\s]+$/,
          "Account holder name should only contain alphabets and spaces"
        )
        .required("Account holder name is required"),
  }),
  upi_id: yup.string().when("bank_details_option", {
    is: "upi_details",
    then: () => yup.string().trim().required("UPI ID is required"),
  }),
});

export const CreateJWPValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "First name should only contain alphabets and spaces"
    )
    .required("First name is required"),
  middle_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Middle name should only contain alphabets and spaces"
    )
    .optional(),
  last_name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Last name should only contain alphabets and spaces"
    )
    .required("Last name is required"),
  date_of_birth: yup
    .date()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "Date of Birth is not valid"
    )
    .required("Date of birth is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Email Address is required"),
  registered_mobile_number: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
  communication_perference: yup
    .array()
    .of(tradeSchema)
    .required("Communication perference is required")
    .min(1, "At least one communication perference is required"),
  business_category: yup
    .string()
    .trim()
    .required("Business category is required"),
  business_address: yup
    .string()
    .trim()
    .required("Business address is required"),
  business_postal_code: yup
    .string()
    .trim()
    .matches(/^[1-9][0-9]{5}$/, "Invalid pincode")
    .required("Business postal code is required"),
  business_phone_number: yup
    .string()
    .required("Phone Number is required")
    .matches(/^[0-9]{10}$/, "Phone Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Phone Number", (value) => {
      return value ? true : false;
    }),
  house_number: yup.string().trim().required("House number is required"),
  pincode: yup
    .string()
    .trim()
    .matches(/^[1-9][0-9]{5}$/, "Invalid pincode")
    .required("Pincode is required"),
  bank_details_option: yup
    .string()
    .trim()
    .required("Bank details are required"),
  account_number: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () => yup.string().trim().required("Account number is required"),
  }),
  confirm_account_number: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () =>
      yup.string().trim().required("Confirm account number is required"),
  }),
  ifsc_code: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () => yup.string().trim().required("IFSC is required"),
  }),
  account_holder_name: yup.string().when("bank_details_option", {
    is: "bank_details",
    then: () =>
      yup
        .string()
        .trim()
        .matches(
          /^[A-Za-z\s]+$/,
          "Account holder name should only contain alphabets and spaces"
        )
        .required("Account holder name is required"),
  }),
  upi_id: yup.string().when("bank_details_option", {
    is: "upi_details",
    then: () => yup.string().trim().required("UPI ID is required"),
  }),
});

export const CreateSectorValidationSchema = yup.object().shape({
  sectorNameEn: yup
    .string()
    .required("Sector Name English is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Sector Name English should only contain alphabets, numbers, and special characters"
    ),
  sectorNameHi: yup
    .string()
    .required("Sector Name Hindi is required")
    .trim()
    .matches(
      /^[\u0900-\u097F0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Sector Name Hindi should only contain Hindi alphabets, numbers, and special characters"
    ),
  sectorNameHn: yup
    .string()
    .required("Sector Name Hinglish is required")
    .trim()
    .matches(
      /^[\u0900-\u097FA-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Sector Name Hinglish should only contain alphabets, numbers, and special characters"
    ),
});

export const CreateOccupationValidationSchema = yup.object().shape({
  occupationNameEn: yup
    .string()
    .required("Occupation Name English is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Occupation Name English should only contain alphabets"
    ),
  occupationNameHi: yup
    .string()
    .required("Occupation Name Hindi is required")
    .trim()
    .matches(
      /^[\u0900-\u097F0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Occupation Name Hindi should only contain Hindi alphabets"
    ),
  occupationNameHn: yup
    .string()
    .required("Occupation Name Hinglish is required")
    .trim()
    .matches(
      /^[\u0900-\u097FA-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Occupation Name Hinglish should only contain alphabets"
    ),
  ncoId: yup.string().required("This field is required"),
});

export const CreateJobRolesValidationSchema = yup.object().shape({
  jobRoleNameEn: yup
    .string()
    .required("Job Role Name English is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Job Role Name English should only contain alphabets"
    ),
  jobRoleNameHi: yup
    .string()
    .required("Job Role Name Hindi is required")
    .trim()
    .matches(
      /^[\u0900-\u097F0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Job Role Name Hindi should only contain Hindi alphabets"
    ),
  jobRoleNameHn: yup
    .string()
    .required("Job Role Name Hinglish is required")
    .trim()
    .matches(
      /^[\u0900-\u097FA-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Job Role Name Hinglish should only contain alphabets"
    ),
  ncoCode: yup
    .string()
    .trim()
    .required("NCO Code is required")
    .matches(
      /^[0-9]+(\.[0-9]+)?$/,
      "NCO Code should be a numeric or decimal value"
    ),
  hiringCreditPerVacancy: yup
    .string()
    .trim()
    .required("Hiring Credit per vacancy is required")
    .matches(/^[0-9]+$/, "It should be a numeric value"),
  nsqfLevel: yup
    .string()
    .trim()
    .required("NSQF Level is required")
    .matches(/^[0-9]+$/, "NSQF Level should be a numeric value"),
});

export const CreateRecruiterClientValidationSchema = yup.object().shape({
  employerName: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-z\s]+$/,
      "Employer name should only contain alphabets and spaces"
    )
    .required("Company Name is required"),
  organizationName: yup
    .string()
    .trim()
    .required("Organization Name is required"),
  tradeOrBrandName: yup
    .string()
    .trim()
    .required("Trade/Brand Name is required"),
  organizationSector: yup
    .mixed()
    .test(
      "is-string-or-object",
      "Organization Sector must be a string or an object",
      (value) => {
        return (
          typeof value === "string" ||
          (typeof value === "object" && value !== null)
        );
      }
    )
    .required("Organization Sector is required"),
});

export const CreateRecruiterValidationSchema = yup.object().shape({
  companyName: yup.string().trim().required("Company Name is required"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().trim().required("Last Name is required"),
  phone: yup
    .string()
    .required("Contact Number is required")
    .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits")
    .test("valid-mobile", "Invalid Contact Number", (value) => {
      return value ? true : false;
    }),
  // email: yup.string().email('Invalid email format').required('Email Name is required'),

  // recruitOnBehalfOfClients: yup.string().trim().required('Dashboard Type is required'),
  fullAddress: yup.string().trim().required("Company Address is required"),
  businessRoles: yup.string().trim().required("Designation is required"),
  sectorType: yup.object().required("Company Sector is required"),
  businessCategory: yup
    .string()
    .trim()
    .required("Business Category is required"),
  // usertype: yup.string().trim().required('Dashboard Type is required'),

  email: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Email Address is required"),

  companyEmail: yup
    .string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email format"
    )
    .required("Company Email is required"),

  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup
    .string()
    .required("pincode is required")
    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits"),
});

export const UpdateJobValidationSchema = yup.object().shape({
  // jobSector: yup.string().trim().required('Job Sector is required'),
  jobOccupation: yup.string().trim().required("Job Occupation is required"),
  jobRole: yup.string().trim().required("Job Role is required"),
});

export const UpdateJobRejectValidationSchema = yup.object().shape({
  comments: yup.string().trim().required("Comments are required"),
});

export const CreateFirstFormValidationSchema = yup.object().shape({
  campaignName: yup.string().trim().required("Campaign name is required"),
  campaignTag: yup.object().required("Campaign Tag is required"),
  campaignType: yup.string().trim().required("Campaign Type is required"),
  sendLater: yup.boolean(),
  scheduleTime: yup
    .date()
    .nullable()
    .when("sendLater", {
      is: true,
      then: (schema) =>
        schema
          .required("Date is required when sending later")
          .min(new Date(), "Date cannot be in the past"),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const CreateSecondFormValidationSchema = yup.object().shape({
  dataSource: yup.string().trim().required("Data source is required"),
  condition: yup.string().when("dataSource", {
    is: (value) => value !== "CSV",
    then: () => yup.string().trim().required("Condition is required"),
  }),
});

export const CreateThirdFormValidationSchema = yup.object().shape({
  templateId: yup.string().trim().required("Template id is required"),
});

export const EmailCampaignFirstFormValidationSchema = yup.object().shape({
  campaignName: yup.string().trim().required("Campaign Name is required"),
  tag: yup.object().required("Campaign Tag is required"),
  campaignType: yup.string().trim().required("Campaign Type is required"),
  sendLater: yup.boolean(),
  scheduledTime: yup
    .date()
    .nullable()
    .when("sendLater", {
      is: true,
      then: (schema) =>
        schema
          .required("Date is required when sending later")
          .min(new Date(), "Date cannot be in the past"),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const EmailCampaignSecondFormValidationSchema = yup.object().shape({
  dataSource: yup.string().trim().required("Data Source is required"),
  condition: yup.string().trim().required("Condition is required"),
  // startDate: yup.string().trim().required("Date Range is required"),
});

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EmailCampaignThirdFormValidationSchema = yup.object().shape({
  from: yup
    .string()
    .trim()
    .required("Email Address is required")
    .matches(emailRegex, "Please enter a valid email Address"),
  templateId: yup.string().trim().required("Template is required"),
  cc: yup
    .string()
    .trim()
    .nullable()
    .matches(emailRegex, "Please enter a valid email Address for CC"),
  bcc: yup
    .string()
    .trim()
    .nullable()
    .matches(emailRegex, "Please enter a valid email Address for BCC"),
});

export const NotificationCampaignFirstFormSchema = yup.object().shape({
  notificationName: yup
    .string()
    .trim()
    .required("Notification Name is required"),
  tag: yup.object().required("Tag is required"),
  notificationType: yup
    .string()
    .trim()
    .required("Notification Type is required"),
  sendLater: yup.boolean(),
  scheduledTime: yup
    .date()
    .nullable()
    .when("sendLater", {
      is: true,
      then: (schema) =>
        schema
          .required("Date is required when sending later")
          .min(new Date(), "Date cannot be in the past"),
      otherwise: (schema) => schema.nullable(),
    }),
  modeOfSend: yup.string().trim().required("Notfication Type is required"),
});

export const NotificationCampaignSecondFormSchema = yup.object().shape({
  condition: yup.string().required("Condition is required"),
  jobId: yup
    .string()
    .nullable()
    .when("condition", {
      is: "Job Id",
      then: (schema) => schema.required("Job Id is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  pincode: yup
    .string()
    .nullable()
    .when("condition", {
      is: "Pincode",
      then: (schema) =>
        schema
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
      otherwise: (schema) => schema.nullable(),
    }),
  radius: yup
    .number()
    .nullable()
    .when("condition", {
      is: "Pincode",
      then: (schema) =>
        schema
          .required("Radius is required")
          .moreThan(0, "Radius must be greater than zero"),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const NotificationBannerCampaignSecondFormSchema = yup.object().shape({
  condition: yup.string().required("Condition is required"),
  jobId: yup
    .string()
    .nullable()
    .when("condition", {
      is: "Job Id",
      then: (schema) => schema.required("Job Id is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  pincode: yup
    .string()
    .nullable()
    .when("condition", {
      is: "Pincode",
      then: (schema) =>
        schema
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
      otherwise: (schema) => schema.nullable(),
    }),
  radius: yup
    .number()
    .required("Radius is required")
    .moreThan(0, "Radius must be greater than zero"),
});

export const thirdFormNotificationValidationSchema = yup.object().shape({
  title: yup.string().trim().required("Notification Title is required"),
  description: yup.string().required("Notification Body is required"),
  // .matches(
  //   /^[A-Za-z\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
  //   "Notfication Body should only contain alphabets and spaces"
  // ),
});

export const thirdFormBannerValidationSchema = yup.object().shape({
  tagText: yup.string().trim().required("Tag Text is required"),
  titleEn: yup
    .string()
    .required("Title in english is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Title in english should only contain alphabets"
    ),
  titleHi: yup
    .string()
    .required("Title in hindi is required")
    .trim()
    .matches(
      /^[\u0900-\u097F0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Title in hindi should only contain Hindi alphabets"
    ),
  titleHn: yup
    .string()
    .required("Title in hinglish is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Title in hinglish should only contain alphabets"
    ),
  bannerType: yup.string().trim().required("Banner Type is required"),

  redirectTo: yup
    .string()
    .nullable()
    .when("bannerType", {
      is: "redirect",
      then: (schema) => schema.required("Banner Type is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  bodyEn: yup
    .string()
    .required("Body in english is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Body in english should only contain alphabets"
    ),
  bodyHi: yup
    .string()
    .required("Body in Hindi is required")
    .trim()
    .matches(
      /^[\u0900-\u097F0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Body in hindi should only contain Hindi alphabets"
    ),
  bodyHn: yup
    .string()
    .required("Body in hinglish is required")
    .trim()
    .matches(
      /^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]*$/,
      "Body in hinglish should only contain alphabets"
    ),
  ctaButtonText: yup.string().trim().required("Button text is required"),
  expiryDateAndTime: yup
    .date()
    .min(new Date(), "Expiry date should not be previous date")
    .required("Expiry Date and Time is required"),
});
