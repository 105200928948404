import "./userQueries.scss";
import * as React from "react";
import { Box, Button, Chip, Typography, Tabs, Tab } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  getRequestWithToken,
  patchRequestWithToken,
} from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const UserQueries = () => {
  const [userQueriesData, setUserQueriesData] = useState([]);
  const [activeQuery, setActiveQuery] = useState(null);
  const [openQuery, setOpenQuery] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);
  const [canRead, setCanRead] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [searchBarValue, setSearchBarValue] = useState("");

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [tabvalue, setTabValue] = useState(0);

  const getContactingReason = (category) => {
    switch (category) {
      case "1":
        return "I want a Job";
      case "2":
        return "I want to hire";
      case "3":
        return "Others";

      default:
        return "";
    }
  };
  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorFn: (row) => {
        const { name } = row || {};
        return <span style={{ textTransform: "capitalize" }}>{name}</span>;
      },
      header: "Full Name",
      size: 200,
      enableColumnFilter: false,
    },
    {
      accessorKey: "email",
      header: "Email Address",
      size: 200,
      id: "email_address",
    },
    {
      accessorKey: "mobile",
      header: "Mobile Numer",
      size: 200,
      id: "mobile_number",
    },

    {
      accessorKey: "rating",
      header: "Rating",
      size: 150,
      enableColumnFilter: false,
    },

    {
      accessorFn: (row) => <span>{getContactingReason(row?.category)}</span>,
      id: "contacting_reason",
      header: "Reason for contacting",
      size: 250,
      enableColumnFilter: false,
    },

    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },

    {
      accessorKey: "z_action",
      header: "Action",
      size: 180,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Box style={{ display: "flex", gap: "2rem" }}>
          {canRead && (
            <div
              style={{
                cursor: "pointer",
                color: "blue",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              onClick={() => {
                if (
                  row?.original?.message?.length > 0 ||
                  row?.original?.fileUrl?.length > 0
                ) {
                  setActiveQuery(row?.original);
                  setOpenQuery(true);
                } else {
                  handleSnackBar(
                    true,
                    "No User query message and attachment found",
                    "error"
                  );
                }
              }}
            >
              <span>View Message </span>
              <MailOutlineIcon style={{ width: "20px", height: "20px" }} />
            </div>
          )}
        </Box>
      ),
    },
  ];

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Contact Queries";
    const permissions = checkPermission(resourceToUpdate);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Contact Queries"));
  };

  const table = useMaterialReactTable({
    columns,
    data: userQueriesData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      color: "primary",
    },

    // muiCircularProgressProps: {
    //   style: {
    //     color: "secondary",
    //     display: "block",
    //   },
    // },

    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });
  const handleCloseQuery = () => setOpenQuery(false);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const getUserQueries = async () => {
    if (!userQueriesData.length) {
      setLoadingDataInGrid(true);
    } else {
      setIsRefetching(true);
    }

    try {
      // const filterParams = {
      //   // global: globalFilter,
      //   ...Object.fromEntries(
      //     columnFilters.map((filter) => [filter.id, filter.value])
      //   ),
      // };
      // const filterQueryString = new URLSearchParams(filterParams).toString();
      const response = await getRequestWithToken(
        `admin/contactus?page=${pagination.pageIndex + 1}&limit=${
          pagination.pageSize
        }&resolve=${tabvalue === 1 ? true : false}`
      );

      setUserQueriesData(response?.data?.data);
      setRowCount(response?.data?.totalCount);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
    }
    setIsRefetching(false);

    setLoadingDataInGrid(false);
    setShowLoader(false);
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue, searchBarValue]);

  useEffect(() => {
    getUserQueries();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    tabvalue,
  ]);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [tabvalue]);

  const ResolveQuery = async () => {
    try {
      const response = await patchRequestWithToken(
        `admin/contactus?resolve=true&id=${activeQuery?._id}`
      );
      handleSnackBar(
        true,
        "You have successfully approved this query",
        "success"
      );
      getUserQueries();
      setShowLoader(true);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
    } finally {
      setOpenQuery(false);
    }
  };
  return isIncludesValue ? (
    <Box className="user-queries-section">
      <ModalComponent
        openModal={openQuery}
        setOpenModal={setOpenQuery}
        modalTitle="Resolve Query"
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseQuery}
      >
        <Box style={{ padding: "10px" }}>
          <Box
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
              Message
            </Typography>
            <MailOutlineIcon style={{ width: "20px", height: "20px" }} />
          </Box>

          <Typography variant="body1" style={{ margin: "5px 0px" }}>
            {activeQuery?.message}
          </Typography>
          <br></br>

          {activeQuery?.fileUrl?.length > 0 && (
            <Box
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                Attachments
              </Typography>
              <AttachFileIcon style={{ width: "20px", height: "20px" }} />
            </Box>
          )}
          <Box
            style={{
              margin: "15px 0px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >  
            {activeQuery?.fileUrl?.length > 0 && activeQuery?.fileUrl?.map((attachment, index) => {
              if(attachment === "noupload") return "No Attachements Found";
              return ( 
                <div key={index} style={{ position: "relative" }}>
                  
                  <img
                    src={attachment}
                    alt="attachment"
                    style={{
                      width: "180px",
                      height: "180px",
                      objectFit: "cover",
                      padding:"8px",
                    }}
                  />

                  <a
                    style={{
                      position: "absolute",
                      bottom: "-13px",
                      right: " -12px",
                    }}
                    href={attachment}
                    download={attachment?.split("/").pop()}
                    target="_blank"
                  >
                    <DownloadForOfflineIcon />
                  </a>
                </div>
              );
            })}
          </Box>

          {!activeQuery?.resolve && (
            <Box style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="error"
                style={{ marginRight: "10px" }}
                onClick={handleCloseQuery}
                disabled={!canUpdate}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                disabled={!canUpdate}
                onClick={ResolveQuery}
              >
                Resolve
              </Button>
            </Box>
          )}
        </Box>
      </ModalComponent>
      <Typography className="section-title">Contact Us Queries</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabvalue}
          onChange={currentTabSelect}
          aria-label="basic tabs example"
        >
          <Tab
            label="Pending Queries"
            style={{ fontWeight: "700" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Resolved Queries"
            style={{ fontWeight: "700" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        {showLoader ? <ReusableLoader /> : <MaterialReactTable table={table} />}
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default UserQueries;
