import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import "./employer.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { CreateEmployerValidationSchema } from "../../utils/Validations";
import {
  patchRequestWithToken,
  postRequest,
  getRequestWithToken,
  getRequest,
  postRequestBeta,
} from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { useLocation, Link, useNavigate } from "react-router-dom";
import FocusError from "../../components/FocusError/FocusError";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const WalletAmount = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [initialValues, setInitialValues] = useState({
    euid: "",
    organisationName: "",
    mobileNumber: "",
    emailAddress: "",
    currentPlan: "",
    totalBalance: 30,
    hiringCredits: "",
    rupees: "",
    transactionType: "",
    comments: "",
  });
  const [edit, setEdit] = useState(false);

  // console.log(location, "this is location");
  const inputRefs = {};

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Employer";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Employer"));
  };

  const handleSubmit = async (values) => {
    console.log(values, "values");
  };

  const currentPlans = [
    "Anubhav (Starter)",
    "Udbhav (Growth)",
    "Uday (Professional)",
    "Udyam (Enterprise)",
  ];

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
    setShowLoader(false);
    // if (location.state?._id) {
    //   getEmployerById();
    // } else {
    //   setShowLoader(false);
    // }

    // if (location?.state?._id) {
    //   const { employer = {} } = dataById;

    //   const foundObject = sector.find(
    //     (obj) => obj._id === dataById?.sectorType
    //   );
    //   setSectorObject(foundObject);
    //   setFileImg(dataById?.businessLogo);

    //   const {
    //     usertype = "employer",
    //     companyName = dataById?.companyName,
    //     firstName = dataById?.firstName,
    //     middleName = dataById?.middleName ? dataById?.middleName : "",
    //     lastName = dataById?.lastName,
    //     phone = dataById?.phone,
    //     companyEmail = dataById?.companyEmail,
    //     fullAddress = dataById?.companyAddress?.fullAddress,
    //     city = dataById?.companyAddress?.city,
    //     state = dataById?.companyAddress?.state,
    //     pincode = dataById?.companyAddress?.pincode,
    //     image = dataById?.businessLogo,
    //     email = dataById?.email,
    //     businessRoles = dataById?.businessRoles === 1
    //       ? "Business Owner"
    //       : "Employee",
    //     sectorType = foundObject,
    //     businessCategory = dataById?.businessCategory === 1
    //       ? "Independent"
    //       : "Registered",
    //     documentType = dataById?.kycDetails?.documentType === 0
    //       ? ""
    //       : dataById?.kycDetails?.documentType,
    //     documentId = dataById?.kycDetails?.documentId
    //       ? dataById?.kycDetails?.documentId
    //       : "",
    //     recruitOnBehalfOfClients = dataById?.recruitOnBehalfOfClients === false
    //       ? "Employer"
    //       : "Recruiter",
    //   } = employer;

    //   setInitialValues({
    //     usertype,
    //     firstName,
    //     lastName,
    //     middleName,
    //     phone,
    //     companyEmail,
    //     companyName,
    //     fullAddress,
    //     city,
    //     state,
    //     pincode,
    //     image,
    //     email,
    //     businessRoles,
    //     sectorType,
    //     businessCategory,
    //     documentType,
    //     documentId,
    //     recruitOnBehalfOfClients,
    //   });
    //   setEdit(true);
    // } else {
    // setInitialValues({
    //   euid: "",
    //   organisationName: "",
    //   mobileNumber: "",
    //   emailAddress: "",
    //   currentPlan: "",
    //   balance: "",
    //   hiringCredits: "",
    //   rupees: "",
    //   transactionType: "",
    //   comments: "",
    // });
    // setEdit(false);
    // }
  }, [location.state, dataById._id, isIncludesValue]);

  return isIncludesValue ? (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="update-wallet-section" ref={scrollTargetRef}>
          <Container>
            <Box className="update-wallet">
              <Box className="headingPlusBackBtn">
                <BackBtn />
                <h1 className="formHeadings">Adjust Wallet Amount</h1>
              </Box>
              <Box className="update-wallet-form-wrapper">
                <Formik
                  initialValues={initialValues}
                  //   validationSchema={CreateEmployerValidationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, values, setFieldValue, resetForm }) => (
                    <Form className="update-wallet-form">
                      <Box className="backgroundStyle">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Typography style={{ fontWeight: "700" }}>
                              User Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="euid">
                                EUID
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="euid"
                                type="text"
                                placeholder="Enter euid"
                                name="euid"
                                className="form-input"
                                size="small"
                              />
                              {errors.euid && (
                                <Box className="form-error">{errors.euid}</Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="organisationName"
                              >
                                Organisation Name
                              </label>
                              <Field
                                id="organisationName"
                                type="text"
                                placeholder="Enter Organisation Name"
                                name="organisationName"
                                className="form-input"
                              />
                              {errors.organisationName && (
                                <Box className="form-error">
                                  {errors.organisationName}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="mobileNumber"
                              >
                                Mobile Number
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="mobileNumber"
                                type="text"
                                placeholder="Enter Mobile Number"
                                name="mobileNumber"
                                className="form-input"
                                maxLength={10}
                              />
                              {errors.mobileNumber && (
                                <Box className="form-error">
                                  {errors.mobileNumber}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="emailAddress"
                              >
                                Email Address
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="emailAddress"
                                type="text"
                                placeholder="Enter Email Address"
                                name="emailAddress"
                                className="form-input"
                              />
                              {errors.emailAddress && (
                                <Box className="form-error">
                                  {errors.emailAddress}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Typography style={{ fontWeight: "700" }}>
                              Current Plans
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="form-control">
                              <div role="group" aria-labelledby="radio-group">
                                <Grid container spacing={2}>
                                  {currentPlans.map((item, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={3}
                                      key={index}
                                    >
                                      <Box className="form-control">
                                        <label
                                          key={index}
                                          className="form-label plan_label"
                                          htmlFor={item}
                                        >
                                          <Field
                                            disabled={edit}
                                            type="radio"
                                            id={item}
                                            name="currentPlan"
                                            value={item}
                                            checked={
                                              values.currentPlan === item
                                            }
                                          />
                                          {item}
                                        </label>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                              {errors.currentPlan && (
                                <Box className="form-error">
                                  {errors.currentPlan}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Typography style={{ fontWeight: "700" }}>
                              Wallet (Hiring credits)
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <Box
                              className="form-control"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography style={{ fontWeight: "500" }}>
                                Total Balance
                              </Typography>

                              <Field
                                id="totalBalance"
                                type="text"
                                placeholder="Enter total balance"
                                name="totalBalance"
                                className="form-input"
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Typography style={{ fontWeight: "700" }}>
                              Top Up
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="hiringCredits"
                              >
                                Hiring Credits
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="hiringCredits"
                                type="text"
                                placeholder="Enter hiring credits"
                                name="hiringCredits"
                                className="form-input"
                                size="small"
                              />
                              {errors.hiringCredits && (
                                <Box className="form-error">
                                  {errors.hiringCredits}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="rupees">
                                Rupees
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="rupees"
                                type="text"
                                placeholder="Enter rupees"
                                name="rupees"
                                className="form-input"
                                size="small"
                              />
                              {errors.rupees && (
                                <Box className="form-error">
                                  {errors.rupees}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="transactionType"
                              >
                                Transaction Type
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="transactionType"
                                type="text"
                                placeholder="Enter hiring credits"
                                name="transactionType"
                                className="form-input"
                                size="small"
                              />
                              {errors.transactionType && (
                                <Box className="form-error">
                                  {errors.transactionType}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <Typography style={{ fontWeight: "700" }}>
                              Admin Comments
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Field
                              id="comments"
                              name="comments"
                              as="textarea"
                              placeholder="Enter admin comments"
                              className="form-textarea" // Custom class for additional styles
                              style={{
                                height: "100px", // Set desired height
                                width: "100%", // Optional: set full width
                                resize: "none", // Optional: prevent resizing
                                border: " 1px solid #eeeded",
                                borderRadius: "5px",
                                padding: "10px",
                                fontSize: "14px",
                                fontFamily: "inherit",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className="form-button-section">
                        <Button
                          className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >
                          Cancel
                        </Button>

                        <Button
                          className="form-button"
                          variant="contained"
                          type="submit"
                          color="success"
                          disabled={formSubmitted}
                        >
                          {edit ? "Update" : "Create"}
                        </Button>
                      </Box>
                      <FocusError handleSnackBar={handleSnackBar} />
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};
export default WalletAmount;
