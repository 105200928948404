import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "./components/layout/Layout";

const isAuthenticated = () => {
  const token = localStorage.getItem("token");

  if (token) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoute = ({ element: Component }) => {
  const { pathname } = useLocation();

  return isAuthenticated() ? (
    pathname === "/chats" ? (
      <Component />
    ) : (
      <Layout>
        <Component />
      </Layout>
    )
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
