import "./candidates.scss";
import {
  Box,
  Button,
  Stack,
  Alert,
  Tooltip,
  Chip,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { json, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import {
  getRequestWithToken,
  deleteRequest,
  patchRequestWithToken,
} from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import SearchBar from "../../components/searchbar/SearchBar";
import MaterialTable from "../../components/materialTable/MaterialTable";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Cell } from "recharts";

const Candidates = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const handleCompleteProfilesChange = () => {
    setShowCompleteProfiles(!showCompleteProfiles);
  };

  const filteredRows = candidateData.filter((candidate) => {
    const isDeletedMatch =
      showDeletedRows ||
      (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch =
      showCompleteProfiles ||
      (candidate.is_profile_complete &&
        candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewCandidate = () => {
    navigate("/candidate/create");
  };

  const qualificationArray = [
    "Any",
    "10th Pass",
    "12th Pass",
    "Diploma After 10th",
    "Diploma After 12th",
    "Graduate",
    "Post Graduate"
  ]

  const qualificationObj = {
    1: "Any",
    2: "10th Pass",
    3: "12th Pass",
    4: "Diploma After 10th",
    5: "Diploma After 12th",
    6: "Graduate",
    7: "Post Graduate"
  };

  const getQualificationString = (number) => {


    return qualificationObj[number] || "";
  };


  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorKey: 'buid',
      header: 'BUID',
      size: 200,
      enableColumnFilter: false,
    },

    {
      accessorKey: 'full_name',
      header: 'Full Name',
      size: 220,
      id: 'name',
      accessorFn: (row) => {
        const { first_name = "", middle_name = "", last_name = "" } = row;
        return `${first_name} ${middle_name} ${last_name}`.trim();
      },
    },

    {
      accessorKey: 'email',
      header: 'Email',
      size: 200,
    },

    {
      accessorKey: 'mobile',
      header: 'Phone',
      size: 200,
    },

    {
      accessorKey: 'pincode',
      header: 'Pincode',
      size: 200,
    },
    {
      accessorKey: 'district',
      header: 'District / City',
      size: 200,
      enableColumnFilter: false,
    },

    {
      accessorKey: 'state',
      header: 'State',
      size: 200,
      enableColumnFilter: false,
    },

    // {
    //   accessorKey: 'qualification',
    //   header: 'Qualification',
    //   size: 250,
    //   // enableColumnFilter: false,
    //   Cell: ({ row }) => ((row?.original?.qualification).length !== 0 ?
    //     <span>{getQualificationString(row?.original?.qualification)}</span> : "--"
    //   ),
    //   filterVariant: 'select',
    //   filterSelectOptions: qualificationArray
    // },

    {
      accessorKey: 'qualification',
      header: 'Qualification',
      size: 250,
      Cell: ({ row }) => {
        const qualifications = row?.original?.qualification;
        if (!qualifications || qualifications.length === 0) {
          return "--";
        }

        if (Array.isArray(qualifications)) {
          return qualifications.map(qual => getQualificationString(qual)).join(", ");
        }

        return getQualificationString(qualifications);
      },
      filterVariant: 'select',
      filterSelectOptions: qualificationArray
    },

    {
      accessorKey: 'kyc_status',
      header: 'KYC',
      size: 200,
      id: 'kyc',
      Cell: ({ row }) => (
        <span>{row.original.kyc_status ? "True" : "False"}</span>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: 'Creation Date',
      size: 210,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div>
          <table>
            <tbody>
              <tr>
                <td style={{ minWidth: "200px" }}>
                  {new Date(row.original.createdAt).toLocaleString("en-US", {
                    timeZone: "Asia/Kolkata",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    },
    {
      accessorKey: 'source',
      header: 'Source Channel',
      size: 200,
      Cell: ({ row }) => (
        <span style={{ textTransform: "capitalize" }}>
          {row.original.source}
        </span>
      ),
    },
    {
      accessorKey: 'app_download_status',
      header: 'App Download Status',
      size: 170,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <span>{row.original.app_download_status ? "True" : "False"}</span>
      ),
    },
    {
      accessorKey: 'app_last_activity_time',
      header: 'Last App Activity Time',
      size: 210,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div>
          {row.original.app_last_activity_time ? new Date(row.original.app_last_activity_time).toLocaleString(
            "en-US",
            {
              timeZone: "Asia/Kolkata",
            }
          ) : "--"}
        </div>
      ),
    },
    {
      accessorKey: 'jobApplyCount',
      header: 'Total Jobs Applied',
      size: 150,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 150,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) => (
        row.original.deleted?.is_deleted ? (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canDelete && <DeleteIcon color="disabled" />}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && (
              <Tooltip title="View">
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() => navigate(`/candidate/detail/${row.original._id}`)}
                />
              </Tooltip>
            )}

            {/* {canUpdate ? (
              <Tooltip title="Edit">
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() =>
                    navigate("/candidate/create", {
                      state: params.row,
                    })
                  }
                />
              </Tooltip>
            ) : (
              ""
            )} */}

            {canDelete && (
              <Tooltip title="Delete">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => {
                    setCandidateId(row.original._id);
                    setOpenDeleteModal(true);
                  }}
                />
              </Tooltip>
            )}
          </Box>
        )
      ),
    },
    {
      accessorKey: 'isDeleted',
      header: 'Deleted',
      size: 100,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) => {
        const isDeleted = row.original.deleted?.is_deleted !== undefined ? row.original.deleted.is_deleted : false;
        return isDeleted && <Chip variant="outlined" label="Deleted" color="error" />;
      },
    },
  ];

  const deleteCandidate = async () => {
    try {
      let response = await deleteRequest(
        `admin?usertype=job_seeker&&userid=${candidateId}`
      );

      handleSnackBar(true, response.data.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllCandidates();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  const getAllCandidates = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(
        `admin?usertype=job_seeker&page=${parseInt(
          pagination.pageIndex + 1
        )}&limit=${pagination.pageSize}&search=${searchBarValue}`
      );
      // console.log("response", response.data);
      setCandidateData(response?.data?.data);
      setRowCount(response?.data?.totalcount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setShowLoader(false);
      setLoadingDataInGrid(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!candidateData.length) {
        setLoadingDataInGrid(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const qualificationObj = {
          1: "Any",
          2: "10th Pass",
          3: "12th Pass",
          4: "Diploma After 10th",
          5: "Diploma After 12th",
          6: "Graduate",
          7: "Post Graduate"
        };

        const filterParams = Object.fromEntries(
          columnFilters.map(filter => {
            if (filter.id === 'qualification') {
              // Find the key in qualificationObj that matches the filter value
              const qualificationKey = Object.keys(qualificationObj).find(
                key => qualificationObj[key] === filter.value
              );
              return [filter.id, qualificationKey || filter.value];
            }
            return [filter.id, filter.value];
          })
        );

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin?usertype=job_seeker&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
        );

        setCandidateData(response?.data?.data);
        setRowCount(response?.data?.totalcount);
        setIsError(false);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
        setLoadingDataInGrid(false);
      }
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    };

    fetchData();
  }, [columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,]);


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue, searchBarValue]);

  const table = useMaterialReactTable({
    columns,
    data: candidateData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    muiSkeletonProps: {
      animation: 'wave',
    },
    muiLinearProgressProps: {
      color: 'secondary',
    },
    muiCircularProgressProps: {
      color: 'secondary',
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="candidates-section">

      <Box
        className="candidates-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button
            variant="contained"
            color="success"
            onClick={createNewCandidate}
          >
            Create Candidate
          </Button>
        ) : (
          ""
        )}

        {/* <Box style={{ display: "flex", gap: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showDeletedRows}
                  onChange={handleToggleChange}
                />
              }
              label="Include Deleted Users"
              labelPlacement="start"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showCompleteProfiles}
                  onChange={handleCompleteProfilesChange}
                />
              }
              label="Include InComplete Profiles"
              labelPlacement="start"
            />
          </FormGroup>
        </Box> */}
      </Box>
      <Box className="candidates-section-data">
        {showLoader ? (
          <ReusableLoader />
        ) : (
          <MaterialReactTable table={table} />
          // <MaterialTable filteredRows={filteredRows} columns={columns} rowCount={rowCount} isLoading={loadingDataInGrid} pagination={pagination} columnPinning={columnPinning} setPagination={setPagination} setColumnPinning={setColumnPinning} />

        )}
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteCandidate();
        }}
      />
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default Candidates;
