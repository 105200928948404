import React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { getRequestWithToken, deleteRequest } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import MaterialTable from "../../components/materialTable/MaterialTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const WhatsAppCampaign = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const filteredRows = campaignData.filter((candidate) => {
    const isDeletedMatch =
      showDeletedRows ||
      (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch =
      showCompleteProfiles ||
      (candidate.is_profile_complete &&
        candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewCampaign = () => {
    navigate("/whatsapp-campaign/create");
  };

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "campaignId",
      header: "Campaign Id",
      size: 220,
    },
    {
      accessorKey: "campaignName",
      header: "Campaign Name",
      size: 220,
    },
    {
      accessorKey: "templateId",
      header: "Template Id",
      enableColumnFilter: false,
      size: 250,
      Cell: ({ row }) => {
        return row.original.whatsappParams?.templateId || "N/A";
      },
    },
    {
      accessorKey: "numberOfUploads",
      header: "Number Of Uploads",
      enableColumnFilter: false,
      size: 200,
    },
    {
      accessorKey: "campaignType",
      header: "Campaign Type",
      size: 200,
    },
    {
      accessorKey: "scheduledDateOrTime",
      header: "Scheduled Date and Time",
      enableColumnFilter: false,
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Date and Time",
      enableColumnFilter: false,
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "csvFile",
      header: "CSV File",
      enableColumnFilter: false,
      size: 150,
      Cell: ({ row }) => (
        <>
          {row.original.csvFile ? (
            <a
              href={row.original.csvFile}
              download
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          ) : (
            "-"
          )}{" "}
        </>
      ),
    },
    {
      accessorKey: "action",
      header: "Action",
      enableColumnFilter: false,
      size: 200,
      Cell: ({ row }) => {
        const currentTime = new Date();
        const scheduledDateOrTime = new Date(
          row?.original?.scheduledDateOrTime
        );

        if (scheduledDateOrTime > currentTime && canDelete) {
          return (
            <Tooltip title="Delete Campaign">
              <DeleteIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCampaignId(row.original.campaignId);
                }}
              />
            </Tooltip>
          );
        }

        return "";
      },
    },
  ];

  const deleteCampaign = async () => {
    try {
      let response = await deleteRequest(
        `/admin/campaign?campaignId=${campaignId}`
      );
      response = response.data;
      if (response?.status === "Success") {
        handleSnackBar(true, response?.msg, "success");
        setOpenDeleteModal(false);
        getAllCampaign();
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  // const getAllCampaign = async () => {
  //   setLoadingDataInGrid(true);
  //   try {
  //     let response = await getRequestWithToken(
  //       `admin/campaign?modeofsend=whatsapp&page=${parseInt(
  //         pagination.pageIndex + 1
  //       )}&limit=${pagination.pageSize}`
  //     );
  //     setCampaignData(response.data.data);
  //     setRowCount(response.data.totalCount);
  //     setLoadingDataInGrid(false);
  //     setShowLoader(false);
  //   } catch (error) {
  //     handleSnackBar(
  //       true,
  //       error?.response?.data?.message || error?.message,
  //       "error"
  //     );
  //     setShowLoader(false);
  //     setLoadingDataInGrid(false);
  //   }
  // };


  const getAllCampaign = async () => {
    if (!campaignData.length) {
      setLoadingDataInGrid(true); // Show main loading indicator if no data
    } else {
      setIsRefetching(true); // Show refetching spinner if data exists
    }

    try {
      const filterParams = {
        ...Object.fromEntries(columnFilters.map((filter) => [filter.id, filter.value])),
      };

      const filterQueryString = new URLSearchParams(filterParams).toString();

      const response = await getRequestWithToken(
        `admin/campaign?modeofsend=whatsapp&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
      );

      setCampaignData(response?.data?.data);
      setRowCount(response?.data?.totalCount);
      setIsError(false);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      setIsError(true);
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } finally {
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    }
  };


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Marketing";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Marketing"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  useEffect(() => {
    getAllCampaign();
  }, [columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,]);

  const table = useMaterialReactTable({
    columns,
    data: filteredRows,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "No Data Found",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="candidates-section">
      <h3 style={{ margin: "0px" }}>WhatsApp Campaign</h3>
      <Box
        className="candidates-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button
            variant="contained"
            color="success"
            onClick={createNewCampaign}
          >
            Create Campaign
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box className="candidates-section-data">
        {showLoader ? (
          <ReusableLoader />
        ) : (
          // <MaterialTable
          //   filteredRows={filteredRows}
          //   columns={columns}
          //   rowCount={rowCount}
          //   isLoading={loadingDataInGrid}
          //   pagination={pagination}
          //   columnPinning={columnPinning}
          //   setPagination={setPagination}
          //   setColumnPinning={setColumnPinning}
          // />
          <MaterialReactTable table={table} />
        )}
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this campaign ?"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteCampaign(campaignId);
        }}
      />
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default WhatsAppCampaign;
