import React, { useMemo } from "react";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useRef } from "react";
import { getRequestWithToken, deleteRequest } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import MaterialTable from "../../components/materialTable/MaterialTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const Notifications = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);
  const [tabvalue, setTabValue] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // const handleToggleChange = () => {
  //   setShowDeletedRows(!showDeletedRows);
  // };

  // const handleCompleteProfilesChange = () => {
  //   setShowCompleteProfiles(!showCompleteProfiles);
  // };

  const filteredRows = campaignData.filter((candidate) => {
    const isDeletedMatch =
      showDeletedRows ||
      (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch =
      showCompleteProfiles ||
      (candidate.is_profile_complete &&
        candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewNotification = () => {
    navigate("/notifications/create");
  };
  const BannerColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },

    {
      accessorKey: "name",
      header: "Banner Name",
      size: 220,
    },

    {
      accessorKey: "title_en",
      enableColumnFilter: false,
      header: "Title (En)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.en}</span>,
    },

    {
      accessorKey: "title_hn",
      enableColumnFilter: false,
      header: "Title (Hn)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.hn}</span>,
    },

    {
      accessorKey: "title_hi",
      enableColumnFilter: false,
      header: "Title (Hi)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.hi}</span>,
    },

    {
      accessorKey: "tag",
      enableColumnFilter: false,
      header: "Tag Name",
      size: 150,
    },

    // {
    //   accessorKey: "ctaButton",
    //   header: "CTA Button Text",
    //   size: 200,
    // },

    {
      accessorKey: "y_createdAt",
      header: "Date and Time",
      enableColumnFilter: false,
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell?.row?.original?.expiry).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },

    {
      accessorKey: "sendLater",
      header: "Send Later",
      enableColumnFilter: false,
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "y_expiry",
      enableColumnFilter: false,
      header: "Expiry Date and Time",
      size: 280,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell?.row?.original?.expiry).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "z-action",
      enableColumnFilter: false,
      header: "Action",
      size: 200,
      Cell: ({ row }) => {
        const currentTime = new Date();
        const scheduledDateOrTime = new Date(row?.original?.sendLater);
        if (scheduledDateOrTime > currentTime && canDelete) {
          return (
            <Tooltip title="Delete Campaign">
              <DeleteIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCampaignId(row.original._id);
                }}
              />
            </Tooltip>
          );
        }

        return "";
      },
    },
  ];

  const NotificationColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },

    {
      accessorKey: "campaignId",
      header: "Campaign Id",
      size: 220,
    },

    {
      accessorKey: "campaignName",
      header: "Campaign Name",
      size: 220,
    },
    {
      accessorKey: "numberOfUploads",
      header: "Number Of Uploads",
      size: 200,
      enableColumnFilter: false,
    },

    {
      accessorKey: "scheduledDateOrTime",
      header: "Scheduled Date and Time",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Date and Time",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const currentTime = new Date();
        const scheduledDateOrTime = new Date(
          row?.original?.scheduledDateOrTime
        );

        if (scheduledDateOrTime > currentTime && canDelete) {
          return (
            <Tooltip title="Delete Campaign">
              <DeleteIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCampaignId(row.original.campaignId);
                }}
              />
            </Tooltip>
          );
        }

        return "";
      },
    },
  ];

  const deleteCampaign = async () => {
    try {
      let response;
      if (tabvalue === 0) {
        response = await deleteRequest(
          `/admin/campaign?campaignId=${campaignId}`
        );
      } else {
        response = await deleteRequest(`/admin/banner?id=${campaignId}`);
      }
      response = response.data;
      if (response?.status === "Success") {
        handleSnackBar(true, response?.msg, "success");
        setOpenDeleteModal(false);
        getAllNotificationAndBanner();
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  // const getAllNotificationAndBanner = async () => {
  //   setLoadingDataInGrid(true);
  //   try {
  //     let response;
  //     if (tabvalue === 0) {
  //       response = await getRequestWithToken(
  //         `admin/campaign?modeofsend=mobile notification&sort=-1&page=${parseInt(
  //           pagination.pageIndex + 1
  //         )}&limit=${pagination.pageSize}`
  //       );
  //     } else if (tabvalue === 1) {
  //       response = await getRequestWithToken(
  //         `/admin/banner?sort=-1&page=${parseInt(
  //           pagination.pageIndex + 1
  //         )}&limit=${pagination.pageSize}`
  //       );
  //     }
  //     setCampaignData(response.data.data);
  //     setRowCount(response.data.totalCount);
  //     setLoadingDataInGrid(false);
  //     setShowLoader(false);
  //   } catch (error) {
  //     handleSnackBar(
  //       true,
  //       error?.response?.data?.message || error?.message,
  //       "error"
  //     );
  //     setShowLoader(false);
  //     setLoadingDataInGrid(false);
  //   }
  // };

  const getAllNotificationAndBanner = async () => {
    if (!campaignData.length) {
      setLoadingDataInGrid(true); // Show main loader for initial load
    } else {
      setIsRefetching(true); // Show refetching spinner for subsequent requests
    }

    try {
      const filterParams = {
        ...Object.fromEntries(columnFilters.map((filter) => [filter.id, filter.value])),
      };

      const filterQueryString = new URLSearchParams(filterParams).toString();

      let response;
      if (tabvalue === 0) {
        response = await getRequestWithToken(
          `admin/campaign?modeofsend=mobile notification&sort=-1&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
        );
      } else if (tabvalue === 1) {
        response = await getRequestWithToken(
          `admin/banner?sort=-1&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&${filterQueryString}`
        );
      }

      setCampaignData(response?.data?.data || []);
      setRowCount(response?.data?.totalCount || 0);
      setIsError(false);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      setIsError(true);
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } finally {
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    }
  };


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Marketing";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Marketing"));
  };

  const previousTabValue = useRef(tabvalue);

  useEffect(() => {

    if (previousTabValue.current !== tabvalue) {
      setColumnFilters([]); // Reset column filters when tabvalue changes
      previousTabValue.current = tabvalue; // Update the previous value
    }
    getAllNotificationAndBanner();
  }, [
    tabvalue,
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  const table = useMaterialReactTable({
    columns: useMemo(() => (tabvalue === 0 ? NotificationColumns : BannerColumns), [tabvalue]),
    data: filteredRows,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="candidates-section">
      <h3 style={{ margin: "0px" }}>Notifications</h3>

      <Box
        className="candidates-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button
            variant="contained"
            color="success"
            onClick={createNewNotification}
          >
            Create Notification
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box className="candidates-section-data">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabvalue}
            onChange={currentTabSelect}
            aria-label="basic tabs example"
          >
            <Tab
              label="Notification"
              style={{ fontWeight: "700" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Banner"
              style={{ fontWeight: "700" }}
              {...a11yProps(0)}
            />
          </Tabs>
        </Box>
        {showLoader ? (
          <ReusableLoader />
        ) : (
          // <MaterialTable
          //   filteredRows={filteredRows}
          //   columns={tabvalue === 0 ? NotificationColumns : BannerColumns}
          //   rowCount={rowCount}
          //   isLoading={loadingDataInGrid}
          //   pagination={pagination}
          //   columnPinning={columnPinning}
          //   setPagination={setPagination}
          //   setColumnPinning={setColumnPinning}
          // />
          <MaterialReactTable table={table} />
        )}
        <SureYouWantToDeletModal
          modalTitle=""
          dialogBody={
            tabvalue === 0
              ? "Are you sure you want to delete this notification ?"
              : "Are you sure you want to delete this banner ?"
          }
          buttonText="Yes"
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          onYesClick={() => {
            deleteCampaign(campaignId);
          }}
        />
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default Notifications;
