import { Skeleton } from "@mui/material";
const SkeletonLoader = ({ count = 15, isChat = false }) => {
  return Array.from({ length: count }).map((_, index) => {
    return (
      <div
        className={isChat ? "chatSectionInnerWrapper" : "userInfo"}
        key={index}
      >
        {isChat ? (
          <div
            className={
              index % 2 === 0 ? "sendMessageWrapper" : "userMessageWrapper"
            }
          >
            <div
              className={
                isChat && index % 2 === 0 ? "sendMessage" : "userMessage"
              }
            >
              <Skeleton height={100} />
              <Skeleton height={30} />
            </div>
          </div>
        ) : (
          <Skeleton height={25} />
        )}
      </div>
    );
  });
};

export default SkeletonLoader;
