import "./UserMessages.scss";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Skeleton } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "./SkeletonLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import {
  getRequestChatApi,
} from "../../utils/ApiRequests";
import MessageIcon from "@mui/icons-material/Message";
import InfiniteScroll from "react-infinite-scroll-component";

const UserMessages = () => {
  const [activeUser, setActiveUser] = useState(null);
  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [chatByNumber, setChatByNumber] = useState([]);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingChat, setLoadingChat] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [numberLoader, setNumberLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [userPageStates, setUserPageStates] = useState({});
  const [searchInput, setSearchInput] = useState("");
  // New state variables for user list pagination
  const [userListPage, setUserListPage] = useState(1);
  const [userListTotalPages, setUserListTotalPages] = useState(null);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.length === 10 || searchInput.length === 0) {
      setNumberLoader(true);
      // Reset pagination when searching
      setUserListPage(1);
      // setMobileNumbers([]);
      setHasMoreUsers(true);
    }
  };
  const handleNumberSearch = () => {
    if (searchInput.length === 10) {
      getMobileNumbers(1, true);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchInput.length === 0) {
        getMobileNumbers(1, true);
      }
    }, 800);

    return () => clearTimeout(handler);
  }, [searchInput]);

  useEffect(() => {
    getMobileNumbers(1, true);
  }, []);

  const getMobileNumbers = async (page = 1, isNewSearch = false) => {
    if (page === 1) {
      setLoadingUser(true);
    }
    try {
      let response = await getRequestChatApi(
        `/chat/search_phone_numbers?page=${page}&limit=20${searchInput ? `&find=True&number=${searchInput}` : ""}`
      );

      if (response?.data) {
        setMobileNumbers(prev =>
          isNewSearch ? response?.data?.mobile_numbers : [...prev, ...response?.data?.mobile_numbers]
        );
        setUserListTotalPages(response?.total || 1);
        setHasMoreUsers(page < (response?.total || 1));
      } else {

        if (isNewSearch) {
          setMobileNumbers([]);
        }
        setHasMoreUsers(false);
      }
      setUserListPage(page);
    } catch (error) {
      if (isNewSearch) {
        setMobileNumbers([]);
      }
      setHasMoreUsers(false);
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
    } finally {
      setLoadingUser(false);
      setNumberLoader(false);
    }
  };

  const loadMoreUsers = () => {
    if (!loadingUser && hasMoreUsers) {
      getMobileNumbers(userListPage + 1, false);
    }
  };

  const getChatDetails = async (number, isNewUser = false) => {
    if (!number) return;

    setLoadingChat(true);
    const currentPage = isNewUser ? 1 : (userPageStates[number] || 1);

    if (currentPage === 1) {
      setMainLoader(true);
    }
    try {
      let response = await getRequestChatApi(
        `/chat/parse_chat?mobile_number=${number}&page=${currentPage}&limit=20`
      );
      setTotalPages(response?.total_pages);
      if (isNewUser) {
        setChatByNumber(response?.data?.chats);
      } else {
        setChatByNumber(prev => [...prev, ...response?.data?.chats]);
      }
      setMainLoader(false);
      setUserPageStates(prev => ({
        ...prev,
        [number]: currentPage + 1
      }));
    } catch (error) {
      handleSnackBar(true, error?.response?.message || error?.message, "error");
    } finally {
      setLoadingChat(false);
    }
  };

  const formatMessage = (text) => {
    const boldFormatted = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    const singleBoldFormatted = boldFormatted.replace(
      /\*(.*?)\*/g,
      "<b>$1</b>"
    );
    return singleBoldFormatted.replace(/\n/g, "<br />");
  };

  const handleUserChange = (number) => {
    setActiveUser(number);
    setChatByNumber([]);
    setTotalPages(null);
    getChatDetails(number, true);
  };

  const goToDashboard = () => navigate("/dashboard");

  const renderUserLists = () => {
    if (mobileNumbers.length === 0 && !numberLoader) {
      return (
        <div className="noMessages">
          <p style={{ margin: "0px", textAlign: 'center', color: '#7c7c7c', fontWeight: '500' }}>No Data Found</p>
        </div>
      );
    }
    return mobileNumbers.map((number, index) => (
      <div
        className={`userInfo ${activeUser === number ? "userInfo2" : ""}`}
        key={`${number}-${index}`}
        onClick={() => handleUserChange(number)}
      >
        {number}
      </div>
    ));
  };

  const renderMessages = () => {
    if (
      (!Array.isArray(chatByNumber) || chatByNumber.length === 0) &&
      !loadingChat
    ) {
      return (
        <div className="noMessages">
          <MessageIcon style={{ width: "50px", height: "50px" }} />
          <p style={{ margin: "0px" }}>No messages to display</p>
        </div>
      );
    }

    return chatByNumber.map((chat, index) => (
      <div className="chatSectionInnerWrapper" key={index}>
        <div
          className={
            chat.sender === "bot" ? "sendMessageWrapper" : "userMessageWrapper"
          }
        >
          <div
            className={chat.sender === "bot" ? "sendMessage" : "userMessage"}
          >
            <div
              className="message"
              dangerouslySetInnerHTML={{
                __html: formatMessage(chat.message),
              }}
            />
            <div style={{ paddingTop: '10px' }}>
              <ul>
                {chat.buttons &&
                  (chat.buttons.map((button, index) => (
                    <li key={index} style={{ paddingBottom: '4px' }}>
                      {button.title}
                    </li>
                  )))
                }
              </ul>
            </div>
            <div className="messageTime">
              {new Date(chat.timestamp).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const handleLoadMore = () => {
    if (activeUser && !loadingChat && userPageStates[activeUser] <= totalPages) {
      getChatDetails(activeUser, false);
    }
  };

  return (
    <div className="userMessageSection">
      <div className="userMessageSectionWrapper">
        <div className="backSection" onClick={goToDashboard}>
          <ArrowBackIcon />
          <Typography variant="h6" className="backButton">
            Go to Dashboard
          </Typography>
        </div>

        <Grid container spacing={2} className="userMessageContainer">
          <Grid item xs={0} md={2} style={{ paddingLeft: "0px" }}>
            <div className="userSection">
              <div className="userInnerSection">
                <div className="userSearchSection">
                  <div className="userSearchSection1">
                    {/* <SearchIcon /> */}
                    <input
                      type="text"
                      placeholder="Search"
                      className="userSearchInput"
                      onChange={handleSearch}
                      value={searchInput}
                    />
                    <Button disabled={searchInput.length !== 10} className="searchBtn" onClick={handleNumberSearch}>
                      <SearchIcon />
                    </Button>
                    {/* {numberLoader && <CircularProgress className="numberLoaderClass" />} */}
                  </div>
                </div>
                {loadingUser ? <div className="userListLoader"> <CircularProgress /></div> : <div
                  style={{ marginTop: "10px", padding: "10px", height: "calc(100vh - 180px)", overflow: "auto" }}
                  id="userListWrapper"
                >
                  <InfiniteScroll
                    dataLength={mobileNumbers.length}
                    next={loadMoreUsers}
                    hasMore={hasMoreUsers}
                    loader={<SkeletonLoader count={3} />}
                    scrollableTarget="userListWrapper"
                  >
                    {renderUserLists()}
                  </InfiniteScroll>
                </div>}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={10}>
            <div className="chatSection">
              {activeUser && (
                <Typography variant="h6" className="chatSectionHeader">
                  {!activeUser ? (
                    <Skeleton height={25} style={{ background: "#767676" }} />
                  ) : (
                    `${activeUser}`
                  )}
                </Typography>
              )}

              <div className="chatSectionWrapper" id="chatSectionWrapper">
                {mainLoader ? <div className='forMainLoader'>
                  <CircularProgress /> </div> :
                  <InfiniteScroll
                    dataLength={chatByNumber?.length}
                    next={handleLoadMore}
                    hasMore={userPageStates[activeUser] <= totalPages}
                    loader={
                      loadingChat ? (
                        <SkeletonLoader isChat={true} count={5} />
                      ) : null
                    }
                    scrollableTarget="chatSectionWrapper"
                  >
                    {renderMessages()}
                  </InfiniteScroll>}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserMessages;